.customize-printer1{
    position: relative;
    height: 100%;
    display: grid;
    grid-template: "main aside" 1fr/9fr minmax(420px,1fr);;
    margin-top: 120px;
    grid-gap: 30px;
}
.customize-printer-image-wrapper1{
    
    grid-area: main;
    max-height: 100%;
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    height: max-content;
    top: 170px;
    margin-bottom: 100px;
    
}
.customize-printer-features-wrapper1{
    grid-area: aside;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 150px;
    margin-top: 25px;
}
/* .customize-printer-header-wrapper{
    grid-area: aside;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100px;
    height: 40px;
} */

.customize-printer-header{
    font-weight: 500;
    margin-top: 15px;
    font-size: 36px;
    line-height: 53px;
    color: #212121;
}
.customize-printer-subheader{
    color: var(--Brother-Neutral-Grey-4, #777);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.9px;
}
.cp-header-laptop{
    display: block;
}
.cp-header-mbl{
    display: none;     
}
.cp-order-printer{
    margin-top: 150px;
}

.customize-printer-variable-width .page-title-back-btn {
    display: flex;
    position: fixed;
    cursor: pointer;
    align-items: center;
    z-index: 1000;
  }

@media (min-width: 1440px) {
    .customize-printer-variable-width{
        max-width: 984px !important;
    }
    /* .customize-printer1{
        max-width: 1184px !important;
    } */
}
@media (min-width: 1024px) {
    .customize-printer-variable-width{
        max-width: 784px !important;
    }
    .customize-printer1{
        grid-template: "main aside" 1fr/9fr minmax(350px,1fr);;
    }
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1023px) {
    .customize-printer-variable-width{
        max-width: 600px !important;
    }
}

/* MOBILE */
/* @media (max-width: 767px) { */
@media (max-width: 1023px) {
    .customize-printer1{
        display: block;
        grid-template: none;
        margin-top: 0px;
        padding: 0 30px;
        margin-bottom: 250px;
    }
    .customize-printer-image-wrapper1{
        margin-top: 20px;
        max-height: 100%;
        position: relative;
        top: 0;
        margin-bottom: 0px;
    }
    .cp-header-laptop{
        display: none;
    }
    .cp-header-mbl {
        padding: 0 30px;
        margin-top: 105px;
        display: block;
    }
    .customize-printer-header{
        margin-top: 10px;
        font-size: 26px !important;
        line-height: 43px !important;
    }
    .customize-printer-variable-width .page-title-back-btn {
        position: relative;
        left:2%;
        top:80px
      }
}

.cp-printer-feature-wrapper{
    margin-left: 5px;
    margin-top: 150px;
    margin-right: 5px;
}
.cp-hardware-plan-header{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #212121;
}
.cp-hardware-plan-border{
    margin-top: 11px;
    width: 30px;
    border: 0.5px solid #212121;
}

.cp-subheader{
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: #212121;
}
.cp-subtitle{
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #212121;
}
.cp-card-wrapper{
    cursor: pointer;
    margin-top: 15px !important;
    padding: 20px !important;
    border: 1px solid #DDDDDD;
    border-radius: 6px;
    font-size: 16px;
    line-height: 26px;
    color: #212121;
}
.cp-card-price{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #545454;
}
.cp-learn-more{
    margin-top: 10px;
    font-size: 14px;
    line-height: 23px;
}
.cp-learn-more a{
    color: #1965E1;
}
.cp-extra-title-wrapper{
    margin-top: 150px;
    text-align: center;
}
.cp-extra-title-header{
    font-size: 20px;
    line-height: 32px;
    color: #212121;
    font-weight: 700;
}
.cp-extra-title-price{
    font-size: 16px;
    line-height: 26px;
    color: #545454;
}
.cp-extra-title-list-header{
    cursor: pointer;
    text-align: left !important;
    margin-top: 8px;
    font-size: 16px;
    line-height: 26px;
    color: #007E8F;
    cursor: default;
}
.cp-feature-points-list{
    text-align: left;
    font-size: 16px;
    line-height: 26px;
}
.cp-feature-points-list>li{
    margin-top: 10px;
}
.hardware-inclusive-btn{
    margin-top: 15px;
    font-size: 16px !important;
    line-height: 20px !important;
    padding: 10px 15px 10px 15px;
    width: auto;
    height: auto;
}
.cp-btn-link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #1965E1;
    text-decoration: underline;
    cursor: pointer;
}
.cp-card-selected{
    border: 2.5px solid #007E8F;
}

.customize-printer-variable-width .page-title-back-btn .back-btn-text{
    margin-left: 10px;
}

@media (max-width: 1023px) {
    .cp-printer-feature-wrapper{
        margin-left: 5px;
        margin-top: 55.5px;
        margin-right: 5px;
    }
}
