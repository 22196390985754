.fixed-web-view-header-wrapper{
  z-index: 1000;
  width: 100%;
  position: fixed;
  top: 0;
}
.web-view-header-container {
  display: block;
  height: 55px;
  left: 0px;
  top: 0px;
  background: #0d2ea0;
  box-shadow: 0px 4px 42px -15px rgba(0, 0, 0, 0.2);
}
.web-view-header-wrapper .header-left {
  display: flex;
  position: absolute;
  top: 11px;
  left: 5.5%;
  cursor: pointer;
}

.web-view-header-wrapper .header-right {
  display: flex;
  position: absolute;
  top: 15.5px;
  right: 8.5%;
  cursor: pointer;
}
.web-view-header-wrapper .header-right img {
  width: 18px;
  height: 18px;
}
.space-between{
    margin-right: 15px;
}
.csagent-portal-admin-link{
  background-color: #1965e1 !important;
  border: 1px solid #1965e1 !important;
  width: 155px;
  height: 30px;
  padding: 5px;
  margin-right: 50px;
  margin-top: -1px;
}
@media (min-width: 768px) {
  .web-view-header-wrapper .header-left{
    left: 8.5%;
  }
}