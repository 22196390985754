.plans-learn-more-container{
    background: #F0F6FF;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.plans-learn-more-container h5{
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}
.plans-learn-more-container .learn-more-button{
    margin-left: 30px;
    background: #fff;
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
}
.plans-learn-more-container .learn-more-button a{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}
.plans-learn-more-container .learn-more-button:hover{
    border: 1px solid #1965E1;
}
.brother-plus-join-btn{
    border: none !important;
    color: #000 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    width: 95px;
    height: 25px;
}