.FAQ-wrapper{
    padding: 20px 20px 60px;
}
.FAQ-wrapper h1{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #545454;
}
.FQA-category-wrapper{
    padding: 15px;
    background: #FFFFFF;
    border-radius: 5px;
}
.FQA-category-wrapper:first-child h5{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #545454;
}
.FAQ-list-wrapper{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid rgba(84, 84, 84, 0.1);
    padding: 10px;
    cursor: pointer;
}

.FAQ-list-wrapper .FAQ-list{
    display: flex;
    align-items: baseline;
}
.FAQ-list-wrapper .FAQ-list .FAQ-list-icon{
    /* background: #008393; */
    border-radius: 5px;
    width: 30px;
    height: 30px;
    position: relative;
    top: 8px;
    background: url("../../assets/images/FAQ/IconBg.svg");
}
.FAQ-list-icon img {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 16px;
    height: 16px;
}
.FAQ-list-wrapper .FAQ-list .FAQ-list-title{
    margin-left: 10px;
}
.FAQ-list-wrapper .FAQ-list .FAQ-list-title h5
{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
}
.FAQ-list-wrapper:last-child{
    border:none
}
.sublist-page-title{
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #008393;
    border: 1px solid rgba(0, 0, 0, 0.001);
}
.faq-sublist-category-title{
   line-height: 0px !important;
}
.FQA-details-content-wrapper .content{
    white-space: pre-line;
    vertical-align: bottom;
}
.FQA-details-content-wrapper{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px;
}
.FQA-details-content-wrapper > p{
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #008393;
    border: 1px solid rgba(0, 0, 0, 0.001);
}
.FQA-details-content-wrapper > h1{
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    color: #545454;
    border: 1px solid rgba(0, 0, 0, 0.001);
    text-align: left;
}

.FQA-details-content-wrapper .content{
font-weight: normal;
font-size: 12px;
line-height: 125%;
color: #545454;
}
.FQA-details-content-wrapper .content ul li{
    margin-bottom: 10px;
}
.FQA-details-content-wrapper .content  a{
    color: #008393
}
.FAQ-subcat-list-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: #545454;
    padding: 5px 0px 0px 20px;
}
.FAQ-topcat-list-title{
    padding: 5px 0px 0px 10px;
}