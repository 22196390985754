.admin-sidebar-nav-offcanvas.offcanvas-start{
    padding: 15px;
    width: 292px !important;
}
.admin-sidebar-nav-offcanvas.offcanvas-body{
    padding: 32px 36px 30px 29px !important;
}
.admin-sidebarNav-menu-wrapper{
    padding: 0px;
}
.admin-sidebarNav-sub-menu-wrapper{
    margin-top: 15px;
    padding: 0px;
    display:block;
    transition:height 0.3s ease-in;
}
.admin-sidebarNav-menu-wrapper .main-menu-item-wrapper, .admin-sidebarNav-sub-menu-wrapper .sub-menu-item-wrapper{
    list-style: none;
}
.admin-sidebarNav-menu-wrapper .main-menu-item-wrapper > a{
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 10px;
    border-radius: 8px;
    font-size: 15px;
}
.admin-sidebarNav-menu-wrapper .main-menu-item-wrapper a, .admin-sidebarNav-sub-menu-wrapper .sub-menu-item-wrapper a{
    color: #2c2c2c !important;
    line-height: 1.5;
}
.admin-sidebarNav-menu-wrapper .main-menu-item-wrapper a:hover{
    background-color: #f5f6f8;
    color:#0d2ea0 !important;
}
.admin-sidebarNav-menu-wrapper .main-menu-item-wrapper a:hover span svg{
    fill:#0d2ea0;
    color:#0d2ea0;
}
.admin-sidebarNav-sub-menu-wrapper li{
    margin-bottom: 15px;
}
.admin-sidebarNav-sub-menu-wrapper .sub-menu-item-wrapper a{
    font-size: 14px;
    font-weight: normal;
    padding: 9px 40px 9px 40px;
    border-radius: 8px;
}
.admin-sidebarNav-sub-menu-wrapper .sub-menu-item-wrapper a:hover {
    background-color: transparent;
}
 .main-menu-text{
    width: 70%;
}
.main-menu-icon{
    margin-right: 10px;
}
.main-menu-item-wrapper{
    margin-bottom: 10px;
}
.sub-menu-arrow-indicator{
    margin-right: 10px;
}
#admin-side-nav-offcanvas .offcanvas-header{
    border-bottom: 2px solid #cccccc42 ;
    padding: 15px 15px 15px 15px !important; 
    margin-top: 0px !important; 
}

/** Side Scroll bar **/
.admin-sidebar-nav-offcanvas::-webkit-scrollbar {
width: 4px;
}
.admin-sidebar-nav-offcanvas::-webkit-scrollbar-track {
background: transparent; 
}
.admin-sidebar-nav-offcanvas::-webkit-scrollbar-thumb {
background: #aab7cf; 
}
li.sub-menu-item-wrapper a.active{
    color: #0d2ea0 !important;
}