/* Regular (Roman) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url("/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Roman.otf") format('opentype');
    font-weight: 400;
    font-style: normal;
}

/* Bold (Bd) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Bd.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

/* Bold Condensed (BdCn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-BdCn.otf') format('opentype');
    font-weight: 700;
    font-stretch: condensed;
    font-style: normal;
}

/* Bold Italic (BdIt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-BdIt.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

/* Black (Blk) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Blk.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

/* Black Condensed (BlkCn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-BlkCn.otf') format('opentype');
    font-weight: 900;
    font-stretch: condensed;
    font-style: normal;
}

/* Black Italic (BlkIt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-BlkIt.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

/* Condensed (Cn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Cn.otf') format('opentype');
    font-weight: 400;
    font-stretch: condensed;
    font-style: normal;
}

/* Italic (It) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-It.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

/* Light (Lt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Lt.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

/* Light Condensed (LtCn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-LtCn.otf') format('opentype');
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
}

/* Light Italic (LtIt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-LtIt.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

/* Medium (Md) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Md.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

/* Medium Condensed (MdCn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-MdCn.otf') format('opentype');
    font-weight: 500;
    font-stretch: condensed;
    font-style: normal;
}

/* Medium Italic (MdIt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-MdIt.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

/* Thin (Th) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-Th.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

/* Thin Condensed (ThCn) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-ThCn.otf') format('opentype');
    font-weight: 100;
    font-stretch: condensed;
    font-style: normal;
}

/* Thin Italic (ThIt) */
@font-face {
    font-family: 'Helvetica Neue LT W1G';
    src: url('/public/fonts/Neue\ Helvetica\ W1G/HelveticaNeueLTW1G-ThIt.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}
