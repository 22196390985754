.summary-outer-wrapper{
    padding: 10px;
    margin-bottom: -12px;
}
.payment-summary-wrapper{
    background: #F9F9F9;
    border: 1px solid #008393;
    border-radius: 5px;
}
.payment-summary-content-wrapper{
    padding: 22px 30px;
}
.top-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payment-summary-content-wrapper .left-content h5{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
}
.payment-summary-content-wrapper .right-content .change-plan-link
{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #1965E1;
}
.plan-details{
    margin-top: 30px;
}
.plan-details .plan-name{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 9px;
}
.plan-details .printer-model-type{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}
.plan-details .current-due-price-txt{
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}
.plan-details .current-due-price{
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 9px;
}
.bottom-section{
    border-top: 1px solid #545454;
}
.bottom-section ul{
    margin-top: 20px;
    padding-left: 15px;
}
.bottom-section ul li{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
}
.payment-alert-error{
    width: 600px;
    margin: 25px auto;
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .summary-outer-wrapper{
        width: 600px;
        margin: 0 auto;
        margin-bottom: -12px;
    }
  }
  /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1281px) {
    .summary-outer-wrapper{
        width: 600px;
        margin: 0 auto;
        margin-bottom: -12px;
    }
  }