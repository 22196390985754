.invoice-type-switch-container{
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
}
.invoice-type-switch-container div h5{
    font-size: 16px;
    line-height: 18px;
    margin-right: 15px;
    color: #545454;
    font-weight: 600;
}
.invoice-posting-modal-error .admin-overlay-modal-header h5, .selected-file-error{
    color: #A41C4E;
}

.invoice-posting-modal-success .admin-overlay-modal-header h5, .selected-file-success{
    color: #0e6245;
}
.invoice-data-fetch-btn-wrapper button{
    width: 105px;
    height: 30px;
}