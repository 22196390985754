.form-check {
  text-align: left !important;
}
.ecopro-form-check.form-check-input {
  background: #ffffff;
  border: 0.5px solid #545454;
  border-radius: 2.5px;
}
.ecopro-form-check.form-check-input:checked[type="checkbox"] {
  background-image: url("../../../assets/images/check.svg") !important;
  background: #ffffff;
  border: 0.5px solid #545454;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 6px;
}
.form-check-label {
  text-align: left;
}
.form-check .f-12 {
  text-align: left !important;
}

.rtl {
  margin-left: -30px;
  display: flex;
  align-items: center;
}
.check-reverse{
  margin-left: 10px !important;
  margin-top: -1px;
}

.frtl {
  text-align: left;
  margin-left: 5px !important;
  font-weight: bold !important;
}

.ecopro-form-check.form-check-input.is-valid:checked,
.was-validated .ecopro-form-check.form-check-input:valid:checked {
  background-color: #fff;
}

.was-validated .ecopro-form-check.form-check-input:valid,
.ecopro-form-check.form-check-input.is-valid {
  border: 0.5px solid #545454;
}
.ecopro-form-check.form-check-input.is-valid:focus,
.was-validated .ecopro-form-check.form-check-input:valid:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.ecopro-form-check.web-form-check-input:checked[type="checkbox"] {
  background-image: url("../../../assets/images/web/web-check.svg") !important;
}
