.FAQ-section-component-wrapper{
    margin-bottom: 40px;
}
.FAQ-section-component-wrapper .section-title {
    margin-top: 40px;
    color: #000;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 15px;
    text-align: center;
}
.FAQ-accordion-container .accordion .accordion-item {
    border: none; 
    border-bottom: 1px solid #000 !important; 
    border-radius: unset;
}
.FAQ-accordion-container .accordion .accordion-item .accordion-header{
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.FAQ-accordion-container .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed){
    color: #000;
    box-shadow: none;
    border-bottom: 1px solid #000;
    background-color: #fff;
}
.FAQ-accordion-container .accordion .accordion-item .accordion-header .accordion-button::after{
    background-image: url("../../../assets/images/Plans_FAQ_accordian_down_arrow.svg");
    background-repeat: no-repeat;
}
.FAQ-accordion-container .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after{
    content: "";
    background-image: url("../../../assets/images/Plans_FAQ_accordian_down_arrow.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 10px;
}
.FAQ-accordion-container .accordion .accordion-item .accordion-header .accordion-button:focus {
    border-color: #ffffff;
    box-shadow: none;
}

.FAQ-section-component-wrapper .button-container{
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.FAQ-section-component-wrapper .button-container button{
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    border-radius: unset;
    width: 200px;
    height: 40px;
    background-color: #1965e1;
    border: 1px solid #1965e1;
}
.accordion-body p{
    font-size: 14px;
    line-height: 16px;
    color: rgb(84, 84, 84);
}
.FAQ-accordion-container{
    padding: 0 100px;
}


  /* styles for portable devices like mobile & tablets */
@media only screen and (max-width: 1023px) {
    .FAQ-section-component-wrapper .section-title{
        font-size: 22px;
        line-height: 24px;
    }
    .FAQ-accordion-container{
        padding: 15px;
    }
    .FAQ-section-component-wrapper .button-container button{
        width: 170px;
    }
    .FAQ-accordion-container{
        padding: 0 0;
    }
}
