.signup-feedback-wrapper h1{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #008393;
}
.signup-feedback-content-wrapper{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px;
    margin: 15px 10px 10px 10px;
}
.signup-feedback-wrapper h5, .signup-feedback-content-wrapper p{
    font-style: normal;
    font-weight: 400;
}
.signup-feedback-wrapper h5{
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.signup-feedback-content-wrapper p{
    font-size: 14px;
    line-height: 16px;
}
.signup-feedback-rating-stars-wrapper{
    overflow: auto;
}
.signup-feedback-rating-stars-wrapper .rating-stars .star{
    float: left;
   cursor: pointer;
}
.signup-feedback-rating-stars-wrapper .rating-stars:not(:first-child) .star{
    margin-left:10px;
}
.signup-feedback-comments-wrapper{
    clear: both;
}
.signup-feedback-rating-stars-wrapper .rating-star-content{
    margin-top: 40px;
    clear: both;
}
.signup-feedback-rating-stars-wrapper .rating-star-content p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
}
.signup-feedback-issues-options-wrapper div {
    text-align: center;
    border: 1px solid #E5E5E5;
    border-radius: 30px;
    width: auto;
    margin: 10px 10px 0px 0px;
    padding: 10px;
    cursor: pointer;
}
.signup-feedback-issues-options-wrapper div span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
}
.signup-feedback-issues-options-wrapper .selected-issuetype{
    background: #008393;
}

.signup-feedback-issues-options-wrapper .selected-issuetype span{
    color: #FFFFFF;
}
.signup-feedback-issues-list-wrapper p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.signup-feedback-buttons-wrapper{
    text-align: center;
}
.signup-feedback-buttons-wrapper p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-decoration-line: underline;
    color: #008393;
    cursor: pointer;
}
.signup-feedback-comments-wrapper .text-area textarea::placeholder{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #545454;
    opacity: 0.5;
}
.signup-feedback-wrapper .header{
    width: 70%;
    margin: 0 auto;
}