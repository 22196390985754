.payment-options-wrapper{
   margin: 0 20px;
}
.payment-options-list{
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}
.payment-options-list.type-web{
    background: #F9F9F9;
}
.payment-options-list.type-mobile{
    background: #FFFFFF;
}
.payment-options-list .list-details-wrapper .list-name{
    margin-top: 14px;
}
.payment-options-list .list-details-wrapper .list-name p{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}
.payment-options-list .list-details-wrapper{
    display: flex;
    align-items: baseline;
}
.payment-options-list .list-details-wrapper  .card-icons{
    margin-left: 10px;
}
.payment-options-list .list-details-wrapper .card-icons img{
    margin-left: 5px;
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
    .payment-options-wrapper{
        width: 600px;
        margin: 0 auto;
    }
  }
  /* 
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
  */
  @media (min-width: 1281px) {
    .payment-options-wrapper{
        width: 600px;
        margin: 0 auto;
    }
  }