.admin-header-outer-wrapper{
  height: 55px;
  left: 0px;
  top: 0px;
  background: #0d2ea0;
  box-shadow: 0px 4px 42px -15px rgba(0, 0, 0, 0.2);
  z-index: 999;
  position: fixed;
  right: 0px;
  display: flex;
  justify-content: space-between;
}
.admin-header-left{
    padding:10px;
    display: flex;
    align-items: center;
}
.admin-header-right{
    padding: 15px;
}
.menu-toggle-icon{
   cursor: pointer;
}
.menu-toggle-icon svg{
    width: 35px;
    height: 35px;
}
.admin-header-logo{
    margin-left: 35px
}
.admin-header-right{
    display: flex;
    align-items: center;
}
.admin-header-right .profile-icon{
    margin-left: 35px;
}
.admin-portal-csagent-link{
    background-color: #1965e1 !important;
    border: 1px solid #1965e1 !important;
    width: 155px;
    height: 30px;
    padding: 5px;
}