
.select-different-device-content {
  padding: 0px 30px 75px 30px;
}
.select-different-device-img {
  width: 60px;
  height: 60px;
  display: flex;
  overflow: hidden;
}
.select-different-device-img img {
  width: 60px;
  height: 60px;
}
.select-different-device .device-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 15px;
}
.device-info div:first-child {
  font-weight: bold;
  margin-bottom: 5px;
}
.select-different-device .container {
  margin-top: 5px !important;
}
.select-different-device .device-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px 10px 15px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}
@media (min-width: 600px) {
  .select-different-device-content {
    padding: 0px 170px 75px 170px;
  }
}

