.device-details {
    display: flex;
    gap: 20px;
}

.device-box {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background: #f9f9f9;
    width: 30%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.device-box h3 {
    font-size: 14px;
    font-weight:600;
    color: #5c5c5c;
    text-align: left; 
    position: absolute;
    top: 16px;
    left: 16px;
}

.device-box p {
    font-size: 40px;
    font-weight: 200;
    color: #007b8c;
    margin: 0;
    text-align: center;
}
