.selected-printer-feature-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.hardware-inclusive-selected-feature-modal .modal-content{
  padding: 37px 64px !important;

}
.hardware-inclusive-selected-feature-modal .modal-header{
  justify-content: space-between;
}
.hardware-inclusive-selected-feature-modal .modal-title{
  font-size: 24px;
  font-weight: 500;
  line-height: 32.4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.hardware-inclusive-selected-feature-modal .cp-subtitle{
  margin-top: 0;
}
.hardware-inclusive-selected-feature-modal .cp-total-price {
  margin-left: 20px;
  color: var(--Brother-Secondary-Teal, #007E8F);
  display: flex;
  align-items: flex-start;
}

.selected-printer-feature-wrapper > div:not(:first-child) {
  margin-left: 10px;
}

.selected-printer-feature-wrapper > div > div:first-child {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}
.selected-printer-feature-wrapper > div > div:not(:first-child) {
  margin-top: 20px;
}
.selected-printer-feature-wrapper > div > div > div:first-child {
  width: 30px;
  border: 0.5px solid black;
  margin-bottom: 10px;
}
.selected-printer-feature-wrapper > div > div > div:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}
.selected-printer-feature-wrapper > div > div > div::not(:first-child),
:not(:nth-child(2)) {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #545454;
}

.spf-image-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}
.spf-image-wrapper img {
  margin-left: 5px;
  width: 23px;
  height: 23px;
  margin-right: 11px;
}
.spf-image-wrapper div {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #545454;
}

@media (min-width: 1440px) {
  .selected-printer-feature-wrapper {
    max-width: 1182px !important;
  }
}

@media (max-width: 1023px) {
  .selected-printer-feature-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 10px 40px;
  }
  .hardware-inclusive-selected-feature-modal .modal-content{
    padding: 0 !important;
  }
  .hardware-inclusive-selected-feature-modal .modal-title {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 24px;
  }
  .hardware-inclusive-selected-feature-modal .cp-subtitle{
    font-size: 24px !important;
  }
  .hardware-inclusive-selected-feature-modal .modal-header img{
    margin-top: -80px;
  } 
  .hardware-inclusive-selected-feature-modal .cp-total-price {
    margin-top: 20px;
     margin-left: 0px;
  }

  .selected-printer-feature-wrapper > div:not(:first-child) {
    margin-top: 40px;
    margin-left: 0px;
  }
}
