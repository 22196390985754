.web-subheader {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
}
.web-subheader > * {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}
.web-subheader .step {
  text-align: center;
  margin-bottom: 5px;
}
.web-subheader .page-title-back-btn {
  display: flex;
  position: absolute;
  left:5%;
  cursor: pointer;
  align-items: center;
}
.web-subheader .page-title-back-btn div {
  display: none;
}
.web-subheader .step-name{
  line-height: 23px;
}
.web-subheader .sub-step-info{
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
}
.web-subheader .step-info{
  width: 350px;
  margin: 23px auto;
  margin-bottom: 13px;
}
.stepInfo-wrapper {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  margin: 4px auto 15px auto;
}
.connect-device-font{
  color: #1E1E1E !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150%; 
}
@media (min-width: 768px) {
  .web-subheader {
    margin-top: 55px;
  }
  .web-subheader .page-title-back-btn {
   left: 8.5%;
  }
  
  .web-subheader .page-title-back-btn div {
    display: block;
    margin-left: 6px;
  }
}
@media (max-width: 375px) {
  .stepInfo-wrapper {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 0 15px;
    margin: 15px auto;
  }
}
