.cs-agent-supply-order-logs-table thead th{
    text-align: center;
}
.cs-agent-supply-order-logs-table tbody td{
    text-align: center;
    vertical-align: middle;
}
.supply-order-product-item-table thead th {
    font-size: 12px;
    line-height: 14px;
}
.cs-agent-supply-order-logs-table tbody tr:last-child, .supply-order-product-item-table tbody tr:last-child{
    border: 0px solid transparent;
}
.supply-order-product-item-table tbody td {
    font-size: 12px;
    line-height: 14px;
}
.supply-order-logs-refresh-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}
.supply-order-logs-refresh-btn button{
    width: 150px;
    height: 45px;
}