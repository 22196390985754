.print-activity-wrapper h1{
    color: #262626;
    margin-bottom: 0px;
}
.print-activity-wrapper{
    padding: 20px;
}
.pa-progress-bar-wrapper{
    margin-top: 10px;
}
.print-activity-csagent-error{
    margin-top: 100px;
}
.print-activity-pages-used-wrapper.displayed-as-child,h2{
    color: #000;
}

.print-activity-avg-pages-used-wrapper.displayed-as-child{
    background-color: #fff;
    height: 100%;
}
.print-activity-pages-used-wrapper.displayed-as-child,
.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper {
    padding: 32px 12px 32px 24px;
    background: #fff;
}
.print-activity-pages-used-wrapper-not-child-comp.displayed-as-child{
    margin-top: 10px;
    padding: 32px 12px 32px 24px;
    background: #fff;
}
.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper {
    height: 100%;
    margin-top: 0;
}

.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper.loader-wrapper,
.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper.error-wrapper {
    padding: 15px 20px;
    height: auto;
}
.pages-used-caption{
    color: #262626;
}


.print-activity-pages-used-wrapper.displayed-as-child h1
{
    font-size: 14px;
    line-height: 16px;
}

.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper  .average-pages-used-content,
.print-activity-pages-used-wrapper.displayed-as-child .pages-used-inner-wrapper .label p,
.print-activity-pages-used-wrapper.displayed-as-child .pages-used-inner-wrapper {
    font-size: 12px;
    line-height: 14px;
}
.print-activity-pages-used-wrapper.displayed-as-child .pages-used-inner-wrapper .pages-used-data-representation-wrapper{
    margin: 0px auto;
}

.print-activity-pages-used-wrapper.displayed-as-child .pages-used-inner-wrapper{
    margin-top: 10px;
}
.print-activity-wrapper.displayed-as-child{
    width: 100%;
    height: 100%;
    padding: 0;
}

.print-activity-pages-used-wrapper.displayed-as-child .pages-used-loader-wrapper,
.print-activity-pages-used-wrapper.displayed-as-child .pages-used-error-wrapper{
    height: 268px;
    margin-top: 15px;
}

.print-activity-pages-used-wrapper.displayed-as-child .pages-used-error-wrapper p,
.print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper.error-wrapper .average-pages-used-content p
{
    margin-top: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #ccc;
}

.fff{
    height: 100%;
}
