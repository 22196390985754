.benefit-of-supplies {
  text-align: center;
}
.supply-service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.supply-service-img {
  max-width: 100%;
  width: 800px;
  margin: auto;
}

.supply-service-img::after {
  display: block;
  position: relative;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 65.88%
  );
  margin-top: -145px;
  height: 150px;
  width: 100%;
  content: "";
}
.supply-service-info {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 45px 15px 45px;
}
.supply-service-info ul{
  text-align: left;
  margin-bottom: 0 !important;
}
.supply-service-info li{
  margin-bottom: 6px;
}
.supply-service-tc {
  margin-top: 15px;
  padding: 0px 30px 17px 30px;
}
.promotion-content span{
  color: #008393;
  font-weight: bold;
}
.benefit-of-supplies .link-wrapper{
  margin-bottom: 20px;
}
.benefit-of-supplies a{
  color: #1965E1;
  text-decoration: underline !important;
}
.benefit-of-supplies-btn{
  border-radius: 0 !important;
  background-color: #4568E6;
  border: 1px solid #4568E6;
}

.teaser-page-prmotion-banner-modal{
  border-radius: 10px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.40);
  width: 255px;
  margin: 0 auto;
}
.teaser-page-prmotion-banner-modal .modal-content {
  background: #E52053;
  color: #fff;
  text-align: center;
  padding: 0;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-one{
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-two{
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* .teaser-page-prmotion-banner-modal .modal-content .txt-one{
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-one .bold-text{
  text-align: center;
  font-size: 20px;
}

.teaser-page-prmotion-banner-modal .modal-content .txt-two{
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.teaser-page-prmotion-banner-modal .modal-content .txt-two .bold-text{
  font-weight: 700;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-three{
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-three .get-bold-text,
.teaser-page-prmotion-banner-modal .modal-content .txt-three .bold-text {
  font-size: 15px;
  font-style: normal;
}
.teaser-page-prmotion-banner-modal .modal-content .txt-three .bold-text{
  font-weight: 700;
} */
.teaser-page-prmotion-banner-modal .modal-content .modal-header{
  padding-bottom: 5px;
  border-bottom: none;
}
.teaser-page-prmotion-banner-modal .modal-content .modal-header svg{
  cursor: pointer;
}
.try-free{
  font-weight: bold !important;
  margin-bottom: 10px;
}
