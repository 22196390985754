.web-nodevice-container{
    margin: 35px 30px 70px 30px;
}

.web-nodevice-img{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.web-nodevice-img img{
    width: 56px;
    height: 50px;

}