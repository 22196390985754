.admin-page-title{
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: #2D2D2D;
}
.admin-page-sub-title{
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #2D2D2D;
    margin-top: -5px;   
}