.discount-tile {
    background-color: #d3b1d6;
    padding: 32px 24px;
    border-radius: 8px;
    text-align: left;
    position: relative;
    font-family: "Helvetica Neue LT W1G";
    cursor: pointer;
    transition: background-color 0.3s;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.discount-tile h1 {
    margin: 0 0 4px 0;
    color: #000;
}

.discount-tile p {
    color: #262626; 
    margin-top: 20px;
    font-weight: 300;
    margin-bottom: 0 !important;
}

.arrowtile {
    font-size: 24px;
    color: #000; 
    display: flex;
    align-items: center;
}
.spinner-overlay{
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);/* Semi-transparent white */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2; 
}

.spinner-status{
    text-align: center;
    color: #fff;
}
.promo-success{
    font-size: 14px
}
.discount-skeleton-loader{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
}