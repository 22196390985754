.plan-cancellation-feedback {
  margin-top: 35px;
  padding: 0px 15px 0px 15px;
}
.plan-cancellation-feedback .header {
  padding: 0 45px 10px 45px;
}
.plan-cancellation-feedback .sub-header {
  padding: 0 45px 30px 45px;
}
.plan-cancellation-border > :not(:last-child) {
  border-bottom: 1px solid rgba(84, 84, 84, 0.1);
}
.plan-cancellation-border > div {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.plan-cancel-card {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px 0px 0px 0px !important;
  border-radius: 5px;
}

.feedback-list {
  text-align: left !important;
}
.selected-feedback {
  background: #008393;
  color: #ffffff;
}
.cancel-continue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
}
.skip_section {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 53px;
  text-decoration: underline;
}
.skip_section a {
  color: #008393 !important;
}
/**
The below style applies to SignupFeedback component also
**/
.text-area {
  margin-top: 15px;
}
textarea {
  resize: none !important;
  width: 100%;
  height: 138px;
  padding: 30px 30px 10px 30px;
  background: #ffffff;
  border: 1px solid rgba(84, 84, 84, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  color: #545454;
}
textarea:focus-visible {
  outline-color: rgba(84, 84, 84, 0.1);
  outline-width: 2px;
}
textarea::placeholder {
  font-weight: bold;
  color: #545454;
}
