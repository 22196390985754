.ecopro-change-device-wrapper{
    cursor: pointer;
    background: #E9D4E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 32px 24px;
    border-radius: 5px;
}
.ecopro-change-device-wrapper .change-device{
    color: #000;
    margin-bottom: 0px;
}
