.eligible-device-container {
  margin: 35px 30px 60px 30px;
}
.eligible-device-header {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #008393;
}
.eligible-device-subheader {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #545454;
  margin-top: 10px;
  margin-bottom: 20px;
}
.eligible-device-list-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #545454;
  text-align: left;
}
.eligible-device-btn {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eligible-device-link {
  margin-top: 20px;
  text-align: center;
}
.eligible-device-link a, .eligible-device-link span {
  margin-top: 20px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline !important;
  color: #008393 !important;
}
.eligible-device-link span{
  cursor: not-allowed;
}