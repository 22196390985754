/* .dashboard-container-flexi-col-1,
.dashboard-container-flexi-col-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
} */

.dashboard-container-flexi-col-2{
    flex-grow: 1;
    margin-top: 10px;
}

/* .ecopro-dashboard-wrapper,
.brother-plus-dashboard-wrapper{
    display: flex;
    flex-direction: column;
    max-width: 1220px;
} */

.dashboard-content-wrapper{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
