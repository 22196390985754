.landing-page-login-banner{
    background: #008393;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    padding: 10px;
}
.landing-page-login-banner .login-text{
    color: #fff;
    font-weight: 700;
}
.landing-page-login-banner .landing-page-btn{
    background: #fff;
    color: #1965e1;
    border-radius: unset;
    font-weight: 700 !important;
    border: 1px solid #fff;
}
/* mobile */
@media (max-width: 767px) {
    .landing-page-login-banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}