.customer-dashboard-left > h1{
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    color: #2D2D2D;
    word-break: break-word;
}
.customer-details div {
    margin-top: 30px;
}
.customer-details div p{
    font-weight: 400;
    font-size: 14px;
    line-height: 3px;
    color: #545454;
}
.customer-details div .label-value{
    color: #2D2D2D;
}
.customer-dashboard-right .nav-tabs{
    border-bottom: none;
}
.customer-dashboard-right .nav-tabs .nav-link{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #545455;
    cursor: pointer;
}
.customer-dashboard-right .nav-tabs .nav-link.active, .customer-dashboard-right .nav-tabs .nav-link:hover{
    background-color: transparent;
    border:none;
}
.customer-end-session-btn-wrapper .ecopro_btn {
    padding: 12px 15px 12px 15px;
}
.customer-end-session-btn-wrapper button{
    width: auto;
    height: auto;
    border-color: #DB332A;
    background: #DB332A;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}
.tab-btn-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: self-end;
}
.subscription-type-text{
    padding: 5px;
    border-radius: 5px;
    color: #fff !important;
    word-wrap: break-word;
    width: 40%;
    line-height: 20px !important;
    font-size: 12px !important;
    text-align: center;
}
.subscription-type-text.active{
    background: green;
}
.subscription-type-text.inactive {
    background: #db332a;
}
.subscription-type-text.preenrollment{
    background: #008393;
}
#csagent-subscription-cancellation-btn{
    background-color: #db332a;
    border: 1px solid #db332a;
    width: 100px;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.subscription-cancellation-feedback .modal-content{
    height: 500px;
    overflow-y: scroll;
}
.subscription-cancellation-confirmation .modal-content ul li{
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: flex-start;
    font-weight: 100;
}

.csagent-dashboard-right.tab-btn-wrapper .nav{
    flex-direction: column;
    align-items: baseline;
}
.customer-dashboard-left .tab-content{
    margin-left: 25px;
}
.csagent-dashboard-right.tab-btn-wrapper .nav-tabs .nav-link{
    border: none;
}
.csagent-dashboard-common-action-btn-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
} 
.customer-end-session-btn-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.customer-end-session-btn-wrapper h2{
    margin: 5px 22px 0 0;
    font-size: 24px;
    line-height: 26px;
}
