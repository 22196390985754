.admin-card-header .card-header-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.admin-card-header .card-header-container h1{
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #2D2D2D;
}
.admin-card-header .card-header-container button{
    width: 104px;
    height: 35px;
    padding: 8px;
}
.admin-form-submit-btn-wrapper{
    text-align: center;
}
.admin-form-submit-btn{
    width:10%;
    height: 40px;
    margin-right: 10px;
}
.admin-input.is-danger, .admin-form-select.is-danger, .admin-form-check-input.is-danger{
    border: 1px solid #A41C4E;
}
.admin-error.is-danger{
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 14px;
    color: #A41C4E;
    font-weight: bold;
}
