.cs-agent-modal-overview .modal, .admin-modal .modal{
    top:15% !important;
}
.cs-agent-modal-overview .modal-content, .admin-modal .modal-content{
    padding: 30px;
}
.ecopro-modal-content{
    color: #2D2D2D;
}
.ecopro-modal-header h5{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #2D2D2D;
}
.ecopro-modal-header p{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #545454;
}
.ecopro-modal-content p{
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #545454;
}
.ecopro-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
.ecopro-modal-footer .ecopro_btn{
    padding: 12px 15px 12px 15px;
    font-size: 14px;
    line-height: 16px;
    width: auto;
    height: auto;
}
.ecopro-modal-footer :nth-child(2){
    margin-left: 15px;
}
/* ==================================== */
.quantity-content-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(84, 84, 84, 0.1);
    margin-top: 10px;
    padding-bottom: 10px;
}
.quantity-content-wrapper .list-name{
    display: flex;
    justify-content: center;
    align-items: center;
}
.quantity-content-wrapper .list-name p{
    margin-bottom: 0 !important;
}
.quantity-content-wrapper .list-value-wrapper{
    display: flex;
    align-items: center;
}
.quantity-content-wrapper .list-value-wrapper input{
    width: 30px;
    border-radius: 5px;
    background-color: #fff;
    border: 0.5px solid #545454;
    margin: 0px 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #2D2D2D;
}
.quantity-content-wrapper .list-value-wrapper .inc-dec-icon{
    width: 17px;
    height: 17px;
    cursor: pointer;
}  
.quantity-table-wrapper{
    border-bottom: none;
    font-size: 14px;
    line-height: 16px;
} 
.quantity-table-wrapper .quantity{
    padding-right: 13px;
}
.sd-ticket-container .ecopro-modal-content{
    text-align:  left !important;
}
.sd-ticket-container .col-12{
    margin-bottom: 10px;
}