/* styles for tablet landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .pages-left>div>div:first-child{
    width: 23%;
  }
  .print-activity-pages-used-wrapper.displayed-as-child .pages-used-inner-wrapper .pages-used-data-representation-wrapper{
    width: 250px;
  }
  .eco-savings-block-dashboard{
    width: 97.2%;
  }
}
/* styles for laptops and desktops */
@media only screen and (min-width: 1024px) {
    .customer-header-desktop, 
    .payment-option-desktop-step-header, 
    .payment-desktop-step-header,
    .desktop-mobileconnect-forms, 
    .desktop-device-list,
    .post-enrollment-wrapper .desktop-app-back-btn,
    .address-edit-form-desktop-app-wrapper,
    .account-info-normal-view,
    .hamburger-menu-desktop-container {
        display: block;
    }
    .customer-header-mobile, 
    .payment-option-mobile-step-header, 
    .payment-mobile-step-header,
    .mobile-forms, 
    .mobile-device-list,
    .post-enrollment-footer,
    .address-edit-form-mobile-app-wrapper,
    .account-info-carousel-view {
        display: none;
    }
  /* button */
  .default {
    background-color: #1965e1;
    border: 1px solid #1965e1;
  }
  /* confirm device */
  .confirm-device-link a {
    color: #1965e1;
  }
  /* device list */
  .desktop-device-list .item{
    height: auto !important;
  }
  .desktop-device-list .item .model {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }
  .desktop-device-list .item .serial-number {
    margin-top: 0 !important;
    font-size: 14px;
    line-height: 16px;
    color: #777777;
    word-wrap: break-word;
  }
  .post-enrollment-wrapper{
    display: flex;
    min-height: 100vh; 
    padding-left: 256px; /* Reserve space for the fixed hamburger menu */
  }
  
  .post-enrollment-header{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10000;
  }
  
  .post-enrollment-header .web-header-outer-wrapper .header-right{
    top: 8.5px;
  }
  .post-enrollment-header .web-header-outer-wrapper .header-right div img{
    width: 35px;
    height: 35px;
  }
  /** Dashboard Rules */
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper{
    margin-left: 10px;
    margin-top: 0px;
  }
  .dashboard-overview-with-swap-device .row .swap-device-desktop-app.col-5, 
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper.col-5, 
  .dashboard-overview-with-swap-device .row .overages-wrapper .pages-rolled-over.col-5 {
    width: 48.666667%;
  }
  .dashboard-overview-with-swap-device .row  .pages-left{
      margin-left: 10px;
      height: 385px;
  }
  .dashboard-overview-with-swap-device .row .dashboard-display-device{
    height: 385px;
  }
  .dashboard-overview-with-swap-device .row .overages-wrapper{
    width: 100%;
    margin-left: 0px;
  }
  .postenrollment-pages-content-wrapper-centre-positioning{
    width: 600px;
    margin: 55px auto;
    max-width: calc(100% - 256px);
  }
  .brother-plus-dashboard-initial-popup-modal{
    width: 400px;
  }
}

/* styles for portable devices like mobile & tablets */
@media only screen and (max-width: 1023px) {
    .customer-header-desktop, 
    .payment-option-desktop-step-header, 
    .payment-desktop-step-header,
    .desktop-mobileconnect-forms, 
    .desktop-device-list,
    .post-enrollment-wrapper .desktop-app-back-btn,
    .address-edit-form-desktop-app-wrapper,
    .account-info-normal-view,
    .hamburger-menu-desktop-container {
        display: none;
    }
    .customer-header-mobile, 
    .payment-option-mobile-step-header, 
    .payment-mobile-step-header,
    .mobile-forms, 
    .mobile-device-list,
    .address-edit-form-mobile-app-wrapper,
    .account-info-carousel-view {
        display: block;
    }
    /** Dashboard Rules */
    .dashboard-overview-with-swap-device .row .swap-device-desktop-app.col-5{
      width: 48.666667%;
    }
    .dashboard-overview-with-swap-device .row  .pages-left{
      margin-left: 10px;
      height: 385px;
    }
    .dashboard-overview-with-swap-device .row .dashboard-display-device{
      height: 385px;
    }
    .dashboard-overview-with-swap-device .row .overages-wrapper{
      width: 100%;
      margin-left: 0px;
    }
    .dashboard-overview-with-swap-device .row .overages-wrapper .pages-overages-wrapper{
      margin-left: 10px;
      margin-top: 0;
    }
    .plans-learn-more-container{
      flex-direction: column;
      align-items: center;
    }
    .plans-learn-more-container h5{
      font-size: 22px;
      line-height: 24px;
      margin-top: 10px;
    }
    .plans-learn-more-container .learn-more-button{
      margin: 10px 0px 20px 0px;
    }
    /***** EcoPro Dashboard *****/
  .dashboard-content-wrapper {
    flex-direction: column;
  }
  .postenrollment-pages-content-wrapper-full-width{
    margin: 15px !important;
    overflow: hidden !important;
    width: auto !important;
  }
  .headerText{
    padding: 5px 2px 2px 1px;
    font-size: 14px;
    line-height: 16px;
  }
  .dashboard-subscription-status .alert-sub-header, .dashboard-success .alert-sub-header{
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
  }
  .account-info-wrapper{
    flex-direction: column;
  }
  .account-info-wrapper .slick-list{
    height: 310px;
  }
  .eco-savings-block-dashboard{
    width: 100%;
  }
  .print-activity-avg-pages-used-wrapper.displayed-as-child .average-pages-used-wrapper{
    width: 100%;
  }
  .pages-left>div>div:first-child{
    width: 45% !important;
  }
  .eco-savings-block-dashboard{
    margin-top: 0;
  }
  .enrolled-device-details-header{
    display: flex;
    align-items: baseline;
  }

}
