.cp-order-printer-btn{
    margin-top: 50px;
    padding: 10px 0;
    height: auto;
}
.cp-order-printer-container{
    margin-top: 60px;
}
.cp-order-printer-btn-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}