.ecopro-form-select .form-select {
  border: 0.5px solid #545454;
  border-radius: 2.5px;
  background-image: url("../../../assets/images/downArrow.svg") !important;
  background-position-x: 98%;
}
.form-select{
  border: .5px solid #545454;
  border-radius: 0px;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("../../../assets/images/downArrow.svg"),
    url("../../../assets/images/error.svg") !important;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #545454 !important;
  --bs-form-select-bg-icon: none !important;
}

select.dropdown-placeholder,
select.dropdown-placeholder option:first-child {
  font-family: "Helvetica Neue LT W1G" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  color: rgba(84, 84, 84, 0.5) !important;
}
select.dropdown-placeholder option {
  color: #212529;
}



.form-floating .form-select{
  border: none;
  border-bottom: 0.5px solid #545454 !important;
  border-radius: 0;  
  background-color: #f9f9f9;
  padding:0;
}

.form-floating > .form-select {
  height: 52px;
  padding: 25px 0 0 0;
}

.choose{
  visibility: hidden;
}

.form-floating>.form-select.is-valid:focus,.form-floating>.was-validated .form-select:valid:focus{
  border:none !important;
  box-shadow: none !important;
}

.form-floating .form-select:focus{
  color: #1965E1;
  border-bottom: 0.5px solid #1965E1 !important;
  box-shadow: none;
}
.form-select:focus{
  color: #545454;
  border-color: #545454 !important;
  box-shadow: none;
}
.form-select.is-valid:focus, .was-validated .form-select:valid:focus{
  box-shadow: none !important;
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus{
  box-shadow: none !important;
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545 !important;
}

.web-form-brother .form-floating>.form-select~label::after {
  background:#F9F9F9 !important;
}