.ecopro-alert{
    word-break: break-word;
    background: #FDE2DD;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.dashboard-warning{
    background: #FDF8EB !important;
    border: 1px solid #F6E0B4;
}
.dashboard-error{
    background: #FDE2DD !important;
    border: 1px solid #F1D1C7;
}
.dashboard-info{
    background-color: #d1ecf1;
    border: 1px solid #bee5eb;
}
.dashboard-subscription-status{
    background-color: #F6EFD1;
    color: #ED7200 !important;
}
.dashboard-success{
    background-color: #EDF9D5;
}
.alert-wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.alert-danger-img img{
    width: 27px;
    height: 24px;
}

.alert-wrapper-info{
    margin-left: 15px;
}

.alert-wrapper-info div{
    overflow-wrap: break-word;
}

.alert-header{
    /* font-weight: bold; */
    color: #212121;
    margin-bottom: 5px;
}

.alert-close{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-left: 15px;
    padding: 10px;
}

.alert-close img{
    width: 10px;
    height: 14px;
}

.alert-close .ecopro_btn{
    padding: 12px 15px 12px 15px;
}

.alert-close button{
    width: auto;
    height: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
}

/* .dashboard-subscription-status .alert-sub-header, .dashboard-success .alert-sub-header{
    font-size: 16px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 150%;
    letter-spacing: -0.176px;
} */

.dashboard-subscription-status .alert-sub-header{
    color: #ED7200;
    /* font-size: 12px;
    line-height: 14px; */
}
.dashboard-success .alert-sub-header{
    color:#6B930D;
}

.reactivation-button{
    margin-top: 20px;
}

.dashboard-error .alert-sub-header{
    color: #ff0136;
    /* font-size: 12px;
    line-height: 14px; */
}

.brother-plus-welcome-alert{
    background-color: #CCE6E9;
}

.brother-plus-welcome-alert .alert-sub-header{
    color: #003D0F;
    /* font-family: "Helvetica Neue LT W1G";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.176px; */
}
.dashboard-error .alert-wrapper{
    padding: 10px;
    align-items: baseline;
}
.dashboard-error .alert-danger-img img{
    height: 15px;
    width: 15px;
}
