.hamburger-menu-desktop-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 256px;
    height: 100vh;
    background-color: #fff;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
}
.hamburger-menu-desktop-wrapper .header{
    padding: 0 30px!important;
    margin-top: 89px!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hamburger-menu-desktop-wrapper .body{
    padding: 0px 36px 30px 32px!important;
}

.active-link{
    color:#008393 !important;
}

.active-link .row div svg path,
.active-link .row div svg g {
    fill: #008393 !important;
    stroke: #008393 !important;
}

.brother-plus-menu-container .active-link {
    color:#1965E1 !important;
}

.brother-plus-menu-container .active-link .row div svg path,
.brother-plus-menu-container .active-link .row div svg g {
    fill: #1965E1 !important;
    stroke: #1965E1 !important;
}
/**
    This rule applies in 3 places as listed below
    1. HamburgerMenu
    2.EcoproFeedback
    3.EcoproModal
*/
.navigation-disabled{
    pointer-events: none;
    cursor: default;
}


.nav-item {
    position: relative;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow svg{
    width:16px;
    height: 16px;
}

.sub-items {
    padding-left: 28px;
    display: flex;
    flex-direction: column;
}

.sub-item {
    display: block;
    margin-top: 10px;
    text-decoration: none;
    color: #333;
}

.divider {
    height: 1px;
    background-color: #ccc; 
    margin-top: 24px;
}
.hamburger-menu-desktop-wrapper .header .titl h1{
    font-family: "Helvetica Neue LT W1G";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: #11339D
}

.hamburger-menu-desktop-container{
    /* position: fixed; */
    min-height: 100vh;
    /* width: 280px; */
}

.logout-dashboard{
    cursor: pointer;
}

@media (max-width: 768px) {
    .hamburger-menu-desktop-wrapper{
        position: relative;
        box-shadow: none;
    }

    .hamburger-menu-desktop-wrapper .header{
        margin-top: 20px!important;
    }
}
