.popover{
    width: 286px !important;
    height: fit-content;
    top: 99px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 42px 0px rgba(0, 0, 0, 0.2);
    border: none;
  }
  .customer-popover{
    inset: 0px -5% !important;
  }
  .agent-popover{
    inset: 0px -7.8% !important;
  }
  .admin-popover{
    inset: 0 -0.7% !important;
  }
  
  .popover-arrow {
    transform: translate3d(256px, 0px, 0px) !important;
  }
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
  .bs-popover-bottom > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff !important;

  }
  
  .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
  .bs-popover-bottom > .popover-arrow::before {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff !important;

  }
  .popover-header {
    padding: 0;
    justify-content: center;
    background: #ffffff;
    padding: 20px;
    border-bottom: 1px solid #54545410 !important;
    border-top-left-radius: 0px !important; 
    border-top-right-radius: 0px !important;
  }
  .popover-header .row{
    --bs-gutter-x: 0rem !important;
  }
  .popover-img-wrapper{
    margin-right: 10px;
  }
  .popover-img {
    width: 53px;
    height: 53px;
  }
  .popover-img img{
    width: 53px;
    height: 53px;
    border-radius: 5px;
  }
  .container {
    padding-right: 0;
    padding-left: 0;
  }
  .popover-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .popover-name {
    text-align: left !important;
    font-weight: bold !important;
  }
  .popover-mail {
    margin-top: 5px;
    color: #777777 !important;
    text-align: left !important;
  }
  .popover-body {
    padding: 15px 20px 0px 20px !important;
    height: auto;
    border-bottom: 1px solid #54545410 !important;
  }
  .popover-body .nav .container > * {
    padding: 0px !important;
    margin: 0 !important;
    height: auto;
    margin-bottom: 20px !important;
  }
  .user-options {
    text-align: left !important;
    font-weight: bold !important;
  }
  .sign-out {
    padding: 20px;
  }
  .user-options a:hover,  .user-options .sign-out:hover{
    color: #008393;
  }
  .active{
    color: #008393;
  }
  .profile-icon{
    cursor: pointer;
  }

  