.form-control {
  background: #ffffff;
  border: 0.5px solid #545454;
  border-radius: 2.5px;
}
.form-label{
  text-align: left !important;
}
input::placeholder {
  font-family: "Helvetica Neue LT W1G" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(84, 84, 84, 0.5) !important;
}

.form-label{
    margin-bottom: 10px !important;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus{
  box-shadow: none;
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus{
  box-shadow: none !important;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus{
  border-color: #545454;
  box-shadow: none;
}
.err-input:focus {
  box-shadow: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("../../../assets/images/error.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
  border: 0.5px solid #545454;
}
.display-error {
  display: block;
}
.err-input {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("../../../assets//images/error.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
  
}
.err-input:focus{
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
}
.form-input-spinner-wrapper{
  margin-top: 8px;
}

.form-input-spinner-wrapper .spinner-border{
  width:20px;
  height: 20px;
  border-width: 3px;
}

.form-input-spinner-wrapper .spinner-border:before,
.form-input-spinner-wrapper .spinner-border:after{
  position: inherit;
}

.form-floating>.form-control:focus~label::after{
  background:#F9F9F9 !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after{
  background:#F9F9F9 !important;
}
