.settings-page-card-header h1{
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #2d2d2d;
}
.admin-settings-row-wrapper{
    margin-bottom: 15px
}
.maintenance-page-setting-wrapper{
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
}
.maintenance-page-save-btn{
    background-color: #1965e1;
    border: 1px solid #1965e1;
    font-size: 14px;
    line-height: 16px;
    width: 60px;
    height: 30px;
}