.eco-savings-block-dashboard{
  /* margin-top: 15px;
  width: 250px; */
  background-color: #fff;
}

.eco-savings-block-dashboard .icon-name-container{
  display: flex;
  justify-content: flex-start;
  align-items: normal;
}

.eco-savings-block-dashboard .icon-name-container img{
  width: 17px;
  height: 25px;
}

.eco-savings-block-dashboard .icon-name-container h2{
  color: #000;
  margin-left: 10px;
}

.eco-savings-block-dashboard .co2-info{
  padding: 0;
}

.carbon-savings-item-row{
  color: #262626;
  display: flex;
  flex-direction: row;
}