.easy-setup-banner-container{
    width: 520px;
    height: 340px;
    display: flex;
    padding: 20px 20px 20px 0;
    color: #212121;
}

.easy-setup-six-months-banner-container{
    width: 530px;
    height: 360px;
    display: flex;
    color: #212121;
}
  
  .easy-setup-banner-container .column-a img, .easy-setup-six-months-banner-container .column-a img {
    height: 100%;
    object-fit: cover;
  }
  
  .easy-setup-banner-container .column-b-banner {
    margin-left: 10px;
    flex: 1;
    padding: 0 10px 10px 10px;
  }
  .easy-setup-banner-container .column-b-banner h1{
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 1rem !important;
  }
  .easy-setup-banner-container .column-b-banner p{
    margin-bottom: 0 !important;
    color: #545454;
  }

  .easy-setup-banner-container .column-b-banner .learn-more-link{
    margin-top: 20px;
  }

  .easy-setup-banner-container .column-b-banner a{
    text-decoration: underline;
  }
  .easy-setup-six-months-banner-container .column-b-six-month-promotion{
    flex: 1;
    padding: 25px 15px 25px 15px;
    text-align: center;
    background: #e52053;
    color: #fff;
  }
  .bullet-points {
    list-style-type: disc;
    margin-left: 20px;
    padding-left: 0;
    text-align: left;
  }

  .bullet-points li {
      margin-bottom: 10px; 
  }

  .column-b-six-month-promotion .header-1{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 14px !important;
  }
  
  .column-b-six-month-promotion .on-printing{
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }
  .column-b-six-month-promotion .header-2{
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px !important;
  }
  .column-b-six-month-promotion .free-trial{
    display: block;
    font-weight: 700;
  }
  .column-b-six-month-promotion .bullet-points{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 25px !important;
  }
  .bonus-savemore{
    font-size: 20px;
  }
  .twomonths_text{
    color: #e52053 !important;
  }