.pages-printed-wrapper{
    color: #000;
    text-align: left;
    background-color: #fff;
    height: 100%;
}
.printed-pages-count{
    font-family: "Helvetica Neue LT W1G";
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    margin-top: 20px;
}
.pages-printed-info{
    padding: 30px 24px;
}
.pages-printed-title{
    margin-bottom: 0;
}
.pages-printed-sub-title{
    color: #262626;
    margin-bottom: 10px;
}