.grid-container{
    margin-top: 10px;
}
.admin-table.table{
    border: 1px solid rgb(0 0 0 / 13%);
    margin-bottom: 0px;
}
.admin-table thead, .admin-table thead tr, .admin-table thead tr th, .admin-table tbody, .admin-table tr td{
    border:none !important;
}
.admin-table thead tr th, .admin-table.table tbody tr td{
    border-right: 1px solid rgb(0 0 0 / 13%) !important;
}
.admin-table thead tr{
    border-bottom: 1px solid rgb(0 0 0 / 13%) !important;
}
.admin-table.table thead tr th{
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #545454;
}
.admin-table.table tbody{
    border-top: 2px solid #545454;
}
.admin-table tbody tr{
    border: 1px solid rgb(0 0 0 / 13%);
}
.grid-no-records{
    color: #008393;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}
.admin-table.table tbody tr td{
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #2D2D2D;
    padding: 10px;
}
.admin-form-control.admin-table-header-search-input{
    width: 75%;
    margin: 0 auto;
    font-size: 12px;
}
.form-check.admin-table-bulk-action-checkbox-wrapper{
    text-align: center !important;
}
.form-check .form-check-input.admin-table-bulkaction-checkbox{
    float: none;
}
.text-left{
    text-align: left !important;
}
