.admin-user-container{
    margin-top: 55px;
    transition: all 0.3s;
    margin-left: 0px;
    padding:20px;
    color: #2D2D2D;
    font-style: normal;
}
.toggle-sidebar.admin-user-container{
    margin-left: 300px;
}
