.connect-device-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.connect-device-img-wrapper{
    margin: 30px 0 53px 0;
}

.connect-device-btn{
    margin-top: 48px;
}
.connect-device-btn button{
    width: 165px;
    height: 50px;
}

@media only screen and (max-width: 1023px) {
    .connect-device-btn{
        margin-top: 30px;
    }
}
.connect-device-content .find-device-txt{
    text-align: center;
}