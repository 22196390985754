.ecorpo-brother-plus-dashboard-wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: auto;
}

.tile-1 {
  grid-column: 1 / span 2; /* Spans columns 1 and 2 */
}
.tile-2 {
  grid-column: 3; /* Spans only column 3 */
}

/* Row 2 layout */
.tile-3 {
  grid-column: 1; /* Spans only column 1 */
  display: grid;
  grid-template-rows: 1fr 1fr; /* Two equal-height rows */
  gap: 10px; 
}
.tile-4 {
  grid-column: 2 / span 2; /* Spans columns 2 and 3 */
  width: 100%;
  height: 100%;
}

/* Row 3 layout (each tile takes up one column) */
.tile-5 {
  grid-column: 1; /* Column 1 */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.tile-6 {
  grid-column: 2 / span 2; /* Column 2 */
  display: flex;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .ecorpo-brother-plus-dashboard-wrapper {
    grid-template-columns: 1fr; /* Switch to a single column layout */
  }

  .tile-1, .tile-2, .tile-3, .tile-4, .tile-5, .tile-6, .tile-7 {
    grid-column: span 1;
  }
}

























.brother-plus-pages-count-wrapper-desktop .pages-left{
    padding: 15px 20px;
}
/* 
.brother-ecopro-container{
  flex-direction: row;
} */

.brother-plus-warranty-status-genuine{
    color:#6B930D;
}
.brother-plus-warranty-status-non-genuine{
    color: #ff0136;
}

/* .info-wrapper {
    display: flex;
    gap: 10px;
    border-radius: 5px;
    align-items: stretch; 
    flex-grow: 1;
    width: 100%;
    text-align: left;
  }

  .stats-section {
    flex: 4; 
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px; 
  }

  .stats-item {
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
}
  
  .levels-section {
    flex: 7; 
    background-color: #fff;
    border-radius: 5px;
  }

  .half-width-section {
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .empty-space {
    flex: 1;
  } */


  /* @media (max-width: 1023px) {
    .info-wrapper {
      flex-direction: column;
    }
  } */