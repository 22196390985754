.ecopro-maintainance-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 90px;
    color: #212121;
    padding: 0 25px;
    margin-bottom: 150px;
    
  }
  .ecopro-maintainance-wrapper h1{
    font-weight: 700 !important;
  }
  .ecopro-maintainance {
    font-size: 28px;
    line-height: 120%;
  }
  .maintenance-info{
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .more-info {
    color: #545454;
    margin-top: 50px;
  }
  .more-info > p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }
  .more-info > div {
    text-align: center;
    margin-top: 10px;
  }
  
  .more-info a{
    font-size: 16px;
  }
  
  @media (min-width: 768px) {
    .ecopro-maintainance-wrapper {
      margin-bottom: 180px;
    }
  }