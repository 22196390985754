/* Container styling */
.subscription-card-container {
    max-width: 700px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
}

/* Title styling */
.subscription-card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #444;
    text-align: center;
}

/* Individual card styling */
.subscription-card {
    margin-bottom: 20px;
    padding: 15px;
}

/* Row styling */
.subscription-card-row {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    line-height: 1.6;
}

/* Label styling */
.subscription-card-label {
    font-weight: bold;
    color: rgb(0, 131, 147);;
    flex: 1;
}

/* Value styling */
.subscription-card-value {
    flex: 2;
    text-align: right;
    color: #333;
}

/* Divider line */
.subscription-card-divider {
    border-top: 1px solid #ddd;
    margin: 15px 0;
}

/* Status-specific colors */
.status-active {
    color: green;
    font-weight: bold;
}

.status-inactive {
    color: red;
    font-weight: bold;
}

.status-enrollmentsuccessful {
    color: green;
}

.status-enrollmentcancelled {
    color: orange;
}
