.ep-modal-img-wrapper{
    border-radius: 16px 0 0 16px !important;
    background: #C4C4C4;
}
.ep-modal-img-wrapper img{
    border-radius: 16px 0 0 16px !important;
    width:559px;
    height: 559px;
}
.modal-vw{
    max-width: 900px
}
.modal-vw .modal-content{
    background-color: transparent !important;
    border: none !important;
    border-radius: 16px !important;
    padding: 0 !important;
    flex-direction: row !important;
}
.ep-modal-content-wrapper{
    padding: 30px 20px;
}
.ep-modal-content-wrapper>h4{
    margin-top: 39px;
    color: #212121;
    font-weight: 500;
}
.ep-modal-content-wrapper p{
    margin-top: 20px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 75px;
}
.ep-modal-content-wrapper div:nth-child(4){
    color: #212121 !important;
    margin-bottom: 10px;
}
.ep-modal-content-wrapper>div{
    text-align: center;
    font-size: 16px;
    line-height: 26px;
}
.ep-modal-content-wrapper>div:nth-child(5){
    margin-bottom: 20px;
}
.ep-modal-content-wrapper button{
    width: -webkit-fill-available !important;
}
.ep-modal-content-wrapper .close-btn{
    float: right;
}
.ep-modal-content-wrapper .close-btn img{
    width: 15px;
    height: 15px;
}

.close-btn-mbl{
    display: none;
}
.close-btn-laptop{
    cursor: pointer;
}
.ep-modal-wrapper{
    background-color: #fff;
    height: 75vh;
    overflow-y: scroll;
    border-radius: 16px;
}



@media (min-width:992px) and (max-width:1023px){
    .close-btn-mbl{
        display: none !important;
    }
    .close-btn-laptop{
        display: block !important;
        cursor: pointer;
    }
    .ep-modal-img-wrapper{
        border-radius: 16px 0px 0 16px !important;
        height: auto;
    }
    .ep-modal-wrapper{
        height: 75vh;
    }
    .ep-modal-content-wrapper>h4 {
        margin-top: 39px !important;
    }
}

@media (min-width:768px) and (max-width:991px){
    .cp-learn-more-modal{
        padding: 0 70px !important;
    }
    .ep-modal-img-wrapper{
        border-radius: 16px 16px 0 0 !important;
        height: 40vh;
    }
    
    .ep-modal-wrapper{
        height: 90vh;
    }
    .ep-modal-content-wrapper{
        padding: 30px 50px;
    }
}
@media (max-width: 767px) {
    .cp-learn-more-modal{
        padding: 0 30px !important;
    }
    .ep-modal-img-wrapper{
        border-radius: 16px 16px 0 0 !important;
        height: 320px;
    }
    .ep-modal-wrapper{
        height: 90vh;
    }
}


@media (max-width: 1023px) {
    .modal-vw .modal-content{
        flex-direction: column !important;
    }
    .modal-vw{
        margin-bottom: 10px;
    }
    .ep-modal-img-wrapper img{
        width:fit-content;
        height: 368px;
    }
    .cp-learn-more-modal{
        margin-top: 20px;
    }
    .modal-content{
        border-radius: 16px !important;
    }
    
    .close-btn-laptop{
        display: none;
    }
    .close-btn-mbl{
        cursor: pointer;
        float: right;
        margin: 20px;
        width: 32px !important;
        height: 32px !important;
        display: block;
    }
    .ep-modal-content-wrapper>h4{
        margin-top: 0px;
    }   
}

