.hardware-printer-type-wrapper{
    padding: 30px 18px 30px 18px;
}
.hardware-print-img-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.hardware-print-img-wrapper img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}
.hardware-printer-type-wrapper .printer-name{
    text-align: center;
    margin-top: 28px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #1D1D1F;
}
.hardware-printer-type-wrapper .printer-description{
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #1D1D1F;
}
.hardware-printer-type-wrapper .available-options{
    margin-top: 15px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}
.hardware-printer-type-wrapper .available-options-list{
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400
}
.hardware-printer-type-wrapper .starting-from{
    margin-top: 30px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
}
.starting-from span{
    font-weight: 700 !important;
    color: #1D1D1F;
}
.plan-initial-payment{
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
}
.hardware-inclusive-printer-btn{
    margin-top: 10px;
    width: auto;
    height: 37px;
    padding: 0px 27px 0px 27px;
}
