.device-stats-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #ECF2FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 196, 196, 0.08);
    border-radius: 8px;
    padding: 12px 24px;
    color: #000;
    margin-bottom: 10px;
}
.device-stats-info{
    text-align: left;
    margin-left: 20px;
}

.device-stats-wrapper .title{
    font-size: 31.25px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 40.625px */
    letter-spacing: 0.156px;
}

.device-stats-wrapper .sub-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%; 
}