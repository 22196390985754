.display-content{
    padding: 15px 15px 60px;
}
.legaldocs-content-wrapper{
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    color: #545454;
}
.legaldocs-content-wrapper h1{
    font-size: 14px;
    line-height: 16px;
    color: #008393;
    border: 1px solid rgba(0, 0, 0, 0.001);
    font-weight: bold;
}
.legaldocs-content-wrapper h2{
    font-size: 20px;
    line-height: 125%;
    border: 1px solid rgba(0, 0, 0, 0.001);
    font-weight: bold;
}
.legaldocs-content p{
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    color: #545454;
}
.legaldocs-content a{
    color: #008393;
    font-weight: bold;
}
.legaldocs-content ul{
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    color: #545454;
    list-style: none;
    padding-left: 0 !important;
}
.legaldocs-content ul li{
    margin-bottom: 20px;
}
.legaldocs-content ul li > span{
    color: #008393;
    font-weight: bold;
}