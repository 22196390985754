.swap-device-header-content {
  padding: 35px 40px 30px 40px;
}
.swap-device-subheader {
  margin-top: 10px;
}
.swap-device-info {
  padding: 0px 30px 60px 30px;
}
.swap-intermediate {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 40px;
}
.swap-intermediate img {
  display: flex;
  padding: 0px 20px 0px 20px;
  height: 20px;
  width: auto;
}
.swap-line {
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background: #545454;
  opacity: 0.1;
  border-radius: 10px;
}
.swap-device-btn {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}
