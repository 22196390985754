.HW-inclusive-service-unavailable-wrapper{
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 0;
    display: flex;
    align-items: center;
    background-image: url('../../../../../../assets/images/landing-page/landing-cover-laptop.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 500px;
}
.HW-inclusive-service-unavailable-wrapper .message-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 100px 0px;
    width: 45%;
}
.HW-inclusive-service-unavailable-wrapper .message-container .ecopro-logo{
    margin-bottom: 10px;
}
.HW-inclusive-service-unavailable-wrapper .message-container h1 {
    color: #333;
}
.HW-inclusive-service-unavailable-wrapper .message-container p {
    color: #008393;
}