.swap-device-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 15px;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 15px;
  }
  .card-selected {
    border: 1px solid #008393;
  }
  .swap-img-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .swap-device-img {
    width: 60px;
    height: 60px;
    overflow: hidden;
  }
  .swap-device-img img {
    width: 100%;
    height: 60px;
    min-height: 100%;
    min-width: 60px;
  }
  .swap-device-card-info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 15px;
    text-align: left !important;
  }
  .swap-device-card-info div:first-child {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .swap-device-img-adddn {
    padding: 25px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swap-device-img-adddn img{
    width: 30px;
    height: 30px;
  }
  .swap-device-img-check {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .swap-device-img-checked {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }