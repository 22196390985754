.sd-ticket-wrapper label{
    text-align: left !important;

}
.sd-ticket-wrapper textarea{
    height: 90px;
    padding: 10px;
    border: 1px solid #ced4da;
    resize: vertical !important;
}
.sd-ticket-wrapper textarea.is-danger {
  border: 1px solid #a41c4e;
}
.sd-ticket-wrapper textarea:focus-visible {
    outline-color: rgba(84, 84, 84, 0.1);
    outline-width: 2px;
  }
  .sd-ticket-wrapper textarea::placeholder {
    font-size: 14px;
    font-weight: normal;
    color: rgba(84, 84, 84, 0.5) !important;
  }
  .sd-ticket-wrapper .admin-form-select-default-value{
    font-size: 14px;
    font-weight: normal;
    color: rgba(84, 84, 84, 0.5) !important;
  }
  .sd-ticket-wrapper .text-area{
    margin-top: 0 !important;
  }