.ecopro-plan-details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-template-rows: auto; 
  align-items: stretch;
  height: 100%;
  }
  .ecopro-plan-usage-tile-1 {
    grid-column: 1;
   
   
  }
  .ecopro-plan-usage-tile-2 {
    grid-column: 2;
    display: grid;
    grid-template-rows: 1fr 1fr; 
    gap: 10px; 
  }
  .xs-pages-rollover-wrapper, .xs-pages-overage-wrapper{
    margin-top: 10px;
  }
  .pages-left-error{
    color: #262626;
  }

  