.ecopro-alert-wrapper{
    margin-top: 28px;
}
.full-screen-loader{
    position: fixed;
    z-index: 2000;
}

.full-screen-loader .spinner-border{
    width:90px;
    height: 90px;
    border-width: 5px;
}