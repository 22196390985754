.acc-complete-img-wrapper{
    width: 120px;
    height: 120px;
    margin: 50px auto 25px;
  }
  .acc-complete-img-wrapper img{
    width: 120px;
    height: 120px;
  }
  .acc-subcontent-wrapper{
    padding: 0px 16px;
  }
  .acc-complete-btn-wrapper{
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  .trial-price-content{
    opacity: 0.8;
  }