.mobile-header-wrapper header {
    display: block;
    text-align: center;
    height: 104px;
    left: 0px;
    top: 0px;
    background: #0D2EA0;
    box-shadow: 0px 4px 42px -15px rgba(0, 0, 0, 0.2);
  }
  
  header .ecopro_logo {
    margin-top: 49px;
    width: 102px;
    height: 40px;
  }
  
  .mobile-header-wrapper .header-left {
    display: flex;
    position: absolute;
    top: 58.08px;
    left: 5%;
    cursor: pointer;
  }
  
  .mobile-header-wrapper .header-left img {
    width: 12.64px;
    height: 20.83px;
  }
  
  .mobile-header-wrapper .header-right {
    display: flex;
    position: absolute;
    top: 58.08px;
    right: 5%;
    cursor: pointer;
  }
  .bell-icon {
    margin-right: 18px;
  }
  .user-profile {
    width: auto;
  }
  .user-profile img {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
  .header-chevron-left{
    fill:#ffffff;
  }
  