.ecopro-dashboard-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: auto;
    grid-auto-rows: min-content;
    gap: 10px;
    margin-top: 20px;
}
.ecopro-tile{
  border-radius: 8px;
}

.ecopro-tile-1{
    grid-column: 1 / span 2;
}

.column-3-container {
  grid-column: 3;
  grid-row: 1 / 5; 
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ecopro-tile-7 {
  width: 100%;
}

@media (max-width: 768px) {
    .ecopro-dashboard-wrapper{
      grid-template-columns: 1fr;
      grid-template-rows: auto; 
    }
  
    .ecopro-tile-1,.column-3-container{
      grid-column: 1;
      grid-row: auto;
    }
    .column-3-container {
      display: grid; 
      grid-template-columns: 1fr;
    }
  }
  
