.order-details{
    padding: 15px 15px 60px;
}
.order-details-card{
    background: #ffffff;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}
.supplies-shipped-img{
    width: 120px;
    height: 120px;
    border-radius: 5px;
}
.supplies-shipped-img img{
    width: 120px;
    height: 120px;
    padding:20px;
}
.supplies-shipped-header{
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    line-height: 125%;
    font-weight: bold;
}
.order-status{
    margin-top: 10px;
}
.support .arrow-btn{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.order-details .container{
    color:#777777;
}
.supplies-shipped-info{
    margin-top: 30px;
}
.supplies-shipped-info .row{
    padding: 15px 0 15px 0;
    border-top: 1px solid rgba(84, 84, 84, 0.1);
}
.shipping-amount{
    border: none !important;
    padding:0 0 15px 0 !important;
}
.invoice{
    margin-top: 10px;
    color: #008393 !important;
}
.fw-b{
    font-weight: bold !important;
}
.order-details .row :last-child{
    color: #545454;
}
.margin-top-5 > div:not(:first-child){
    margin-top: 5px !important;
}
.support{
    align-items: flex-start !important;
}
.support .container{
    color: #545454;
    margin-top: 30px;
}
.support .row .col:last-child{
    text-align: right;
}
.support .row:first-child{
    padding-bottom: 17px ;
    border-bottom: 1px solid rgba(84, 84, 84, 0.1);;
}
.support .row:last-child{
    padding-top: 17px ;
}
.support-header{
    text-align: left !important;
}
.order-details.set-margin{
    margin-top: -50px;
}

@media (min-width: 481px) and (max-width: 900px){
    .order-details{
        padding: 30px 172px 60px 172px;
    }
}
@media screen and (min-width: 920px) and (max-width: 1281px) {
    .order-details{
        padding: 50px;
    }
}

