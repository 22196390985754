/* .average-pages-used-wrapper {
  margin-top: 30px;
  padding: 0 0px 60px 0px;
} */
.average-page-trends {
  color: #212121;
  margin-bottom: 10px;
}
.average-pages-used-content {
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  border-radius:5px
}
.trends-header {
  margin-bottom: 5px;
}
.average-page-count {
  color: #212121;
}

.trends-date{
  margin-top: 12px;
}

.trends-bar-chart-wrapper-avg-page{
  width: 100%; 
  height: 100%; 
  max-width: 100%; 
  overflow: hidden;
  box-sizing: border-box;
}

.trends-bar-chart-wrapper{
  width: 100%; 
  height: 150px; 
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

