.footer{
    background: #ffffff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;       
}
.footer .privacy-policy{
    margin-right: 10px;
}
.footer a{
    color: #545454 !important;
    cursor: pointer;
}
.footer .terms-use{
    margin-left: 10px;
}
.footer  .privacy-terms{
    margin-bottom: 30px;
}
.hardware-customize-printer-footer{
    bottom: 89px !important;
    z-index: 100;
}
@media (min-width:768px) and (max-width:1023px){
    .hardware-customize-printer-footer{
        bottom: 141px !important;
    }
}
@media (max-width: 767px) {
    .hardware-customize-printer-footer{
        bottom: 141px !important;
    }
}