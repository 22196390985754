.form-floating .form-control {
  background: #f9f9f9;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 0.5px solid #545454;
  border-radius: 0;
}

.form-floating>.form-control:focus{
  border-color: inherit;
  box-shadow: none;
  border-bottom: 1px solid #1965E1 !important;
  color: #1965E1 !important;
}

.form-floating:focus-within label {
  color: #1965E1
}
.form-floating input::placeholder {
  font-family: "Helvetica Neue LT W1G" !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  visibility: hidden;
  color: #f9f9f9 !important;
}

.form-floating > .form-control {
  height: 52px;
  padding: 1rem 0rem;
  /* margin-top: 20px !important; */
}
.form-floating > #italyaddressLine2 {
  margin-top: 20px !important;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  font-weight: 400;
  font-size: 14px;
  padding: 6.3px 1px 6.3px 7.5px !important;
  text-align: left;
  margin-top: 5px
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding-top: 35px;
}
.form-floating > label {
  transform: translateY(-10%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 27px 7.5px 0 7.5px;
  overflow: hidden;
  text-align: start;
  white-space: normal; /* Allow text to wrap */
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  width: 100%;
  box-sizing: border-box;
  height: auto; 
}

.web-form-input .form-control.is-valid,
.web-form-input .was-validated .form-control:valid {
  background-image: none;
  border: 0.5px solid #545454;
}
.web-form-input .display-error {
  display: block;
}
.err-input:focus {
  box-shadow: none !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.form-floating>.form-control:read-only{
  cursor: not-allowed;
}

.web-form-input-spinner-wrapper{
  margin-top: 8px;
}

.web-form-input-spinner-wrapper .spinner-border{
  width:20px;
  height: 20px;
  border-width: 3px;
}

.web-form-input-spinner-wrapper .spinner-border:before,
.web-form-input-spinner-wrapper .spinner-border:after{
  position: inherit;
}
