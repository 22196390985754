  .change-plan-container {
    padding: 35px 30px 60px 30px;
  }
  .change-plan-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #007e8f;
    margin-bottom: 35px;
  }
  .change-plan-list-wrapper {
    background: #ffffff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
  }
  .change-plan-selected {
    border: 1px solid #007e8f;
  }
  .change-plan-name-recommened {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .change-plan-recommened {
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    text-align: right;
    color: #1965e1;
  }
  .change-plan-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #007e8f;
    margin-bottom: 5px;
  }
  .change-plan-pages-per-month {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #545454;
    margin-bottom: 10px;
  }
  .change-plan-price {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(84, 84, 84, 0.75);
  }
  .change-plan-selected-img img {
    width: 20px;
    height: 20px;
  }
  .change-plan-price-selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .change-plan-renew{
    margin-bottom: 20px;
  }
  .change-plan-terms {
    margin-top: 27px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #545454;
  }
  .change-plan-btn{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }