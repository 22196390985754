.cp-total-price{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32.4px;
    display: flex;
    color: #212121;
}


.cp-total-price > div:first-child{
    padding-right: 20px;
    border-right: 1px solid #777777;
}

.cp-total-price > div:last-child{
    padding-left: 20px;
}
.price-with-info {
    display: inline-flex;
    align-items: flex-start; 
}

.vector-exponent {
    cursor: pointer;
    margin-left: 4px; 
    display: flex;
    align-items: flex-start;
}

.vector-image {
    width: 18px; 
    height: 18px; 
}