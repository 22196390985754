.device-not-connected-wrapper{
    display: flex;
    justify-content: normal;
    border-radius: 5px;
    background: #fff;
    padding: 20px 40px 30px 40px;
    margin-top: 15px;
    align-items: center;
    flex-direction: row;
}
.device-not-connected-outer-wrapper.show-lg .printer-icon{
    width: 200px;
}
.device-not-connected-wrapper .content{
    color: #545454;
}
.device-not-connected-wrapper .content h5{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
.device-not-connected-wrapper .content p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}
.device-not-connected-wrapper .content .download-app-highlight-txt{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
}
.device-not-connected-wrapper .content button{
    width: auto;
    padding: 12px 18px;
    height: auto;
    background: #008393;
}
.show-xs .device-not-connected-btn{
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.device-not-connected-btn{
    display: flex;
    justify-content: flex-start;
}
.device-not-connected-btn-2{
    background: #fff !important;
    margin-left: 15px;
    color: #008393;
}
.web-connect-section{
    position: relative;
    justify-content: center;
}
.web-connect-section .apply-overlay-bg{
    background: rgba(255, 255, 255, 0.9) !important;
    z-index: 9999;
    height: 100%;
    position: absolute;
    inset: 0;
}
.show-lg .web-connect-section {
    align-items: flex-start !important;
}
.show-lg .web-connect-section .printer-icon{
    padding: 0 50px 0 30px;
    width: auto !important;
}

.web-connection-instruction{
    color: #545454;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
}
.web-connect-section .printer-icon{
    margin-top: 25px !important;
}
.web-connect-section .printer-img-wrapper .change-device{
    margin-top: 10px;
    color: #1965E1;
    text-align: center;
    font-family: "Helvetica Neue LT W1G";
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}
.show-lg .web-connect-section .printer-img-wrapper .change-device{
    margin-top: 30px;
    text-align: left;
    padding: 0 50px 0 30px;
}
.show-lg .web-connect-section .web-connection-instruction{
    text-align: left;
}
.web-connect-section .support-content{
    margin-top: 40px;
}
.enter-code-wrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 24px;
    color: #545454;
    font-size: 14px;
    line-height: 145%; 
}
.enter-code-container{
    display: flex;
    flex-direction: row;
}

.show-lg .enter-code-container .device-not-connected-btn{
    margin-left: 10px;
}


.enter-code-wrapper .form-control:focus{
    background: #F3F3F3;
}

.enter-code-text{
    margin-bottom: 10px;
}

@media only screen and (min-width: 1024px){
.device-not-connected-wrapper .content button {
    background-color: #008393;
    border: 1px solid #008393;
}
}
@media only screen and (max-width: 1023px) {
    .device-not-connected-wrapper.show-xs{
        display: block;
    }
    .device-not-connected-outer-wrapper.show-lg{
        display: none;
    }
}
@media only screen and (min-width: 1024px) {
    .device-not-connected-wrapper.show-xs{
        display: none;
    }
    .device-not-connected-outer-wrapper.show-lg{
        display: block;
    }
}
.device-not-connected-wrapper.show-xs .printer-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
}
.device-not-connected-outer-wrapper.show-lg .printer-icon{
    margin-right: 15px;
}
.device-not-connected-outer-wrapper.show-lg .printer-icon img{
    width: 69px;
    height: 69px;
}
.device-not-connected-wrapper.show-xs .content{
    text-align: left;
}
.device-not-connected-wrapper .printer-icon a{
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin: 15px 0px 15px 0px;
}
.device-not-connected-wrapper.download-app-section .content button{
    background: none;
    border: none;
}
.skeleton-web-connection .content{
    margin-left: 20px;
    width: 90%;
}
.device-not-connected-wrapper .invalid-feedback{
    font-weight: 700 !important;
} 
.device-not-connected-wrapper .spinner-info .f-20{
    font-weight: 400 !important;
}

.device-not-connected-wrapper .device-successfully-connected img{
    width: 90px;
    height: 90px;
}

.device-not-connected-wrapper .printer-img-wrapper{
    margin-bottom: 20px;
}
.device-not-connected-wrapper .printer-icon{
    margin-bottom: 20px;
}
.web-connect .content{
    text-align: center !important;
}
.web-connection-device-offline .updateAddress-img{
    margin-top: 20px !important;
}
.web-connection-device-offline .updateAddress-btn{
    margin-top: 20px !important;
}
.web-connection-device-offline .updateAddress-btn-margin{
    margin-bottom: 20px;
}
.web-connection-device-offline{
    margin-top: 20px;
    padding: 20px;
    background: #fff;
}
 .device-not-connected-outer-wrapper.show-lg .web-connect .printer-icon{
    width: auto;
}
 .device-not-connected-outer-wrapper.show-lg .web-connect .content{
    margin-left: 30px;
    text-align: left !important;
}