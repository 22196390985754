.ecopro-upgrade-block-wrapper{
    background-color: #CCE6E9;
    border-radius: 8px;
    display: flex;
    height: 100%;
    width: 100%;
}
.ecopro-upgrade-block-wrapper .contents-wrapper{
    padding: 20px;
    color: #071956;
    flex: 1;
    height: 100%;
       
}
.ecopro-upgrade-block-wrapper .contents-wrapper p{
    margin-top: 1.5rem;
    color: #071956;
}
.ecopro-upgrade-now-btn{
    display:inline-flex;
    border: 1px solid #0D2EA0;
    color: #0D2EA0;
    height: 45px;
    width: 140px;
    border-radius: 0;
    justify-content: space-around;
    align-items: center;
    background: transparent;
}
.ecopro-upgrade-now-btn span{
    font-weight: 500 !important;
}
.ecopro-upgrade-block-wrapper .contents-wrapper .button-wrapper{
    margin-top: 40px;
}
.ecopro-upgrade-block-wrapper .image-wrapper{
  flex: 0 0 50%;
  max-width: 50%; 
  height: 100%;
  overflow: hidden; 
}
.ecopro-upgrade-block-wrapper .image-wrapper img{

    width: 100%;
  height: 100%;
  object-fit: cover;

    /* border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    flex-shrink: 0;
    align-self: stretch; */
}

@media (max-width: 599px) {
    .ecopro-upgrade-block-wrapper {
      flex-direction: column;
    }
    .ecopro-upgrade-block-wrapper .contents-wrapper, .ecopro-upgrade-block-wrapper .image-wrapper{
        width: 100%; /* Full width for both elements */
        height: auto;
        max-width: 100%;
    }
    .ecopro-upgrade-block-wrapper .image-wrapper img {
        width: 100%;
        height: auto; /* Allow image to scale naturally with width */
        max-width: 100%; 
      }
  }

