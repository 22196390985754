/** Responsive */
@media (max-width: 767px) {
    .container-fluid,
    .row {
      padding: 0;
      margin: 0;
    }
  }
  @media (max-width: 600px) {
    .container-fluid,
    .row {
      padding: 0;
      margin: 0;
    }
  }
  @media (max-width: 1044px) {
    .container-fluid,
    .row {
      padding: 0;
      margin: 0;
    }
  }
  @media (min-width: 767px) {
    .f-12 {
      font-size: 16.51px;
      line-height: 19px;
    }
    .f-16 {
      font-size: 16px;
      line-height: 18px;
    }
    .f-20 {
      font-size: 27.5167px;
      line-height: 32px;
    }
  }