@import url("mainResponsive.css");
@import url("iprintScanDesktopApp.css");
@import url("font.css");
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#root{
  min-height: 100% !important;
    position: relative !important;
}
html,body{
  margin: 0;
    padding: 0;
    height: 100%;
}
body {
  background: #f5f6f8;
  color: #545454;
  font: 300 16px/22px Arial, Helvetica, sans-serif;
  font-style: normal;
  padding-right: 0 !important /** Added to overwrite the padding applied when bootstrap modal is opening */
}
.teaser-background{
  background: #ffffff !important;
}
:root{
  --PhoneInput-color--focus:#212529
}
.fc-e {
  color: #008393;
}
.fc-g {
  color: #545454;
}
.fc-w {
  color: #ffffff;
}
.fw-b{
  font-weight: bold !important;
}
.ta-left{
  text-align: left !important;
}

.f-12 {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
.f-14{
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  text-align: center;
}
.f-16 {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.f-18{
  font-size: 18px;
  line-height: 21px
}
.f-20 {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.mt-5{
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-60{
  margin-bottom: 60px;
}

.pointer-cursor {
  cursor: pointer;
}
iframe {
  width: 100%;
  height: 640px;
}

.alert button{
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  background: transparent;
  border: none;
}
.link-txt-align{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
}
.link-txt-align:hover, 
.link-txt-align.visited, 
.link-txt-align.focus{
  color: #fff;
  text-decoration: none;
}

a{
 color: #1965E1;
 text-decoration: none;
}

.image-background{
  background: #c4c4c4;
}
.whiteBG{
  background: #ffffff;
}

a.ecopro_btn{
  cursor: pointer;
}
a.ecopro_btn:hover{
  color:#fff;
}

:root{
  --primary-m-blue: #1965E1;
  --neutral-d-grey: #4D4D4D;
  --neutral-white: #FFF;
  --brother-Secondary-Teal: #007E8F;
  --brother-Neutral-Grey-4: #777;

  /* Font Sizes */
  --font-size-h1: 31.25px;
  --font-size-h1-medium: 28px;
  --font-size-h2: 24px;
  --font-size-body-large: 20px;
  --font-size-body-medium: 16px;
  --font-size-body-small: 14px;
  --font-size-caption: 12px;


  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-light: 300;

  /* Line Heights */
  --line-height-h1: 130%; /* 40.625px */
  --line-height-h2: 32.4px;
  --line-height-body-medium-large: 29.1px;
  --line-height-body-large: 180%;
  --line-height-caption: 133.333%; /* 16px */

  /* Letter Spacing */
  --letter-spacing-h1: 0.156px;
  --letter-spacing-body-large: 0.1px;
  --letter-spacing-caption: 0.15px;

  /* Font Family */
  --font-family-primary: "Helvetica Neue LT W1G";
}

.web-signup:hover{
  background-color: #1450B3;
}
.cp-btn-link:hover{
  color: #1450B3;
}
.hardware-btn-secondary{
  color: #545454;
  border: 1px solid #E4E4E4;
  background-color: #E4E4E4;
}
.hardware-btn-secondary:hover{
  background-color: #DDDDDD;
}


::-webkit-scrollbar {
  width: 0; 
  background: transparent; 
}

*{
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.postenrollment-pages-content-wrapper-full-width{
  overflow: auto;
  width: 100%;
  margin: 50px 15px 50px 15px;
  max-width: 1220px;
}


.h1 {
  font-size: var(--font-size-h1, 31.25px);
  font-weight: var(--font-weight-normal, 400);
  line-height: var(--line-height-h1, 130%);
  letter-spacing: var(--letter-spacing-h1, 0.156px);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.h1-medium{
  font-size: var(--font-size-h1-medium, 28px);
  font-weight: var(--font-weight-normal, 400);
  line-height: var(--line-height-h1, 154%);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.h2 {
  font-size: var(--font-size-h2, 24px);
  font-weight: var(--font-weight-normal, 400);
  line-height: var(--line-height-h2, 32.4px);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.body-large {
  font-size: var(--font-size-body-large, 20px);
  font-weight: var(--font-weight-normal,400);
  line-height: var(--line-height-body-large, 180%);
  letter-spacing: var(--letter-spacing-body-large, 0.1px);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.body-medium-large {
  font-size: var(--font-size-body-medium-large, 18px);
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-body-medium-large, 29.1px);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.body-medium {
  font-size: var(--font-size-body-medium, 16px);
  font-weight: var(--font-weight-light, 300);
  line-height: var(--line-height-h1, 130%);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.body-small {
  font-size: var(--font-size-body-small, 14px);
  font-weight: var(--font-weight-normal, 400);
  line-height: normal;
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

.caption {
  font-size: var(--font-size-caption, 12px);
  font-weight: var(--font-weight-normal, 400);
  line-height: var(--line-height-caption, 16px);
  font-family: var(--font-family-primary, "Helvetica Neue LT W1G");
}

