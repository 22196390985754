.confirm-device {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.confirm-device-img {
  width: 200px;
  height: 200px;
  display: flex;
  margin-bottom: 15px;
  overflow: hidden;
}
.confirm-device-img img{
  width: 200px;
  height: 200px;
}
.confirm-device-info {
  margin-top: 5px;
  margin-bottom: 30px;
}
.confrim-device-btn {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.confirm-device-link{
    margin-bottom: 70px;
}
.confirm-device-link a {
  color: #008393;
  text-decoration: underline;
}
