.admin-edit-admin-users{
    padding: 20px;
}
.admin-manage-users > div{
    margin-bottom: 20px;
}
.admin-user-role-label{
  display: block;
}
.admin-revoke-form-submit-btn-wrapper{
  display: flex;
  justify-content: flex-start;
}
.admin-revoke-submit-btn{
  width: 15%;
  height: 50px;
  margin-right: 10px;
}