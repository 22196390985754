.cp-printer-img-wrapper {
    width: 100%;
    max-width: 400px;
    margin: auto;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-self: center;
}

.cp-printer-img-wrapper img{
    max-width: 100%;
    height: auto;
    max-height: 506px;
}

.cp-printer-img-wrapper .slider-container {
    width: 90%;
    position: relative;
}

.cp-printer-img-wrapper .slick-dots {
    position: static !important;
}

.cp-printer-img-wrapper .slick-prev {
    left: -20px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.cp-printer-img-wrapper .slick-next {
    right: -20px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
}

.cp-printer-img-wrapper .slick-prev:before, .cp-printer-img-wrapper .slick-next:before {
    opacity: 0.5;
    color: black;
}

.pi-device-sent {
    margin-top: 20px;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: var(----brother-Secondary-Teal, #007E8F);
}
.pi-delivery-supply-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #212121;
    padding: 22.5px 0;
}
.pi-delivery-supply-wrapper > div:first-child {
    border-right: 1px solid #CCCCCC;
    padding-right: 30px;
}
.pi-delivery-supply-wrapper > div:last-child {
    padding-left: 30px;
}
.pi-free-deliver img, .pi-automated-supply img {
    width: 23px;
    height: 23px;
    margin-right: 12px;
}

@media (min-width: 1024px) {
    .cp-printer-img-wrapper img {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 1023px) {
    .cp-printer-img-wrapper{
        height: auto;
    }
    .cp-printer-img-wrapper img{
        max-width: 100%;
        height: auto;
        max-height: 230px;
    }
    .pi-delivery-supply-wrapper{
        flex-direction: column;
    }
    .pi-delivery-supply-wrapper > div:first-child{
        padding: 0;
        border-right:none;
    }
    .pi-delivery-supply-wrapper > div:last-child{
        margin-top: 22.5px;
        padding: 0;
    }
}