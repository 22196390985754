.admin-form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.admin-form-control.form-control{
    border: 1px solid #ced4da;
}
.admin-form-control:focus{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.admin-form-control.admin-form-control-plaintext{
    border: none !important;
}