.plans-caveat-txt-wrapper{
    margin: 55px 0px 25px 0px;
    text-align: center;
    font-size: 12px;
}
.caveat-txt-link{
    color: #1965e1;
}
.bonus-caveat{
    margin-top: 10px;
}