.carbon-offsetting {
  padding: 15px 15px 60px;
}
.carbon-offsetting .header {
  background: #ffffff;
  border-radius: 5px;
  padding: 30px 0px 30px 0px;
  margin-bottom: 15px;
}
.carbon-offsetting .carbon-value {
  margin-top: 10px;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: #008393;
}
.co2-calculated {
  padding: 12px 0px 12px 0px;
  background: #008393;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.co2-calculated .question-bg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
}
.co2-calculated .question-bg-img img {
  border-radius: 5px;
  width: 25px;
  height: 25px;
}
.co2-calculated .question-title {
  flex: 2;
  margin-left: 10px;
  color: #ffffff;
}
.co2-calculated .right-arrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 33px;
}
.co2-info {
  background: #ffffff;
  border-radius: 5px;
  padding: 30px !important;
}
.co2-info .row {
  padding-bottom: 15px;
}
.co2-calculated-border > :not(:last-child) {
  border-bottom: 1px solid rgba(84, 84, 84, 0.1);
}
.supplies-date {
  margin-top: 5px;
}
.supplies-kg {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  color: #006976;
}
