.BOCcontents{
    color: black;
}
.BOCcontents h2{
    font-size: 20px;
    line-height: 22px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.BOCcontents p{
    font-size: 14px;
    line-height: 16px;
}