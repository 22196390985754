.customer-lookup-box{
    width: 500px;
    margin: 20px auto 25px;
    padding: 35px;
    background: #FFFFFF;
    border-radius: 5px;
}
.lookup-box-content h5{
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #2D2D2D;
}
.lookup-box-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #545454;
    margin-bottom: 5px;
}
.lookup-box-input-wrapper .form-control{
    border-top: 0px;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid rgba(84,84,84, 0.25);
}
.lookup-box-input-wrapper .form-container{
    margin: 0px !important;
}
.lookup-box-input-wrapper .form-brother{
    padding: 0px !important;
}
.lookup-box-input-wrapper .ecopro_btn{
    font-size: 14px;
    line-height: 16px;
    width: 185px;
    height: 40px;
}
.customer-profile-serial-number-input-wrapper::before{
    content: "Serial Number";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1965E1;
}
.customer-profile-email-input-wrapper::before{
    content: "Email Id";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1965E1;
}
.customer-profile-bpid-input-wrapper::before{
    content: "BPID";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1965E1;
}
.customer-profile-input-wrapper.hidden::before{
    display:none;
}
.customer-profile-input-wrapper.input-color-change input{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #1965E1;
    border-bottom: 1px solid #1965E1;
}
.customer-search-input-error{
    color: #dc3545 !important;
    border-bottom: 1px solid #dc3545 !important;
}
.customer-profile-input-wrapper.search-error-wrapper::before{
	Color:#dc3545
}
.or-text{
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 12px;
    color: #545454;
}
#input-field-help-text{
    font-size: 12px;
}