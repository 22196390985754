

.email-log-error{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
    color: #008393;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}
.email-log-container .table>:not(:first-child){
    border-top: 1.5px solid !important;
}