.easy-pay-wrapper{
    margin-top: 22px;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 30px 30px 40px;
}
.try-new-benefit{
    text-align: center;
    color: #2E2E2E;
    margin-bottom: 47px;
    margin-top: 20px;
}
.easy-pay-new{
    color: #007E8F;
    font-weight: 500;
}
.easy-pay-benefit{
    font-weight: 500;
    color: #2E2E2E;
}
.easy-pay-content{
    margin-right: 56px;
}
.easy-pay-title{
    font-weight: 500;
    color: #212121;
    margin-bottom: 30px;
}
.easy-pay-list-wrapper{
    color: #545454;
    font-weight: 400 !important;
    line-height: 25.9px !important;
}
.easy-pay-discount{
    font-weight: 500;
}
.easy-pay-list-wrapper ul{
    margin-top: 30px;
    gap:10px
}

.easy-pay-avail-button{
    height: auto;
    width: auto;
    border: 1px solid #1965E1;
    background:#0761D1;
    padding: 10px 20px;
}
.easy-pay-image-wrapper img{
    width: 632px;
    height: 387px;
}

@media only screen and (max-width: 1023px) {
    .try-new-benefit, .easy-pay-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .easy-pay-content{
        margin-right: 0;
        margin-bottom: 20px;
    }
    .easy-pay-wrapper{
        padding: 0;
        flex-direction: column;
    }
    .easy-pay-list-wrapper, .easy-pay-image-wrapper{
        width: 100%;
        height: auto;
        max-width: 100%;
    }
    .easy-pay-image-wrapper img {
        width: 100%;
        height: auto; /* Allow image to scale naturally with width */
        max-width: 100%; 
      }
  }
