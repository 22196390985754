.zuora-payment-wrapper{
    padding: 10px 10px 60px;
}
/**
Updating this rule will also affect web signup up payment page
*/
.payment-page-outer-wrapper{
    margin-bottom: 40px;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .zuora-payment-wrapper{
        width: 600px;
        margin: 0 auto;
    }
  }

  @media (min-width: 1281px) {
    .zuora-payment-wrapper{
        width: 600px;
        margin: 0 auto;
    }
  }