.form-contianer {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.form-brother {
  padding: 20px 20px 30px 20px !important;
  text-align: left !important;
  background: #ffffff;
  border-radius: 5px;
}
.edit-form{
  background: #ffffff;
}
.form-padding {
  padding: 20px 20px 20px 20px !important;
}
.form-brother-check {
  text-align: left !important;
  padding: 15px 20px !important;
  background: #ffffff;
  border-radius: 5px;
}
.form-heading-label {
  font-weight: bold !important;
  text-align: left !important;
  margin-bottom: 0px !important;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-header-margin{
  margin-bottom: 15px;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("../../assets/images/account-steps-completed-tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
}
.form-btn {
  width: auto !important;
  margin: 20px 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-brother > div:not(:first-child) {
  margin-top: 15px;
}
.row {
  --bs-gutter-x: 0rem;
}
.my-acc-payment-update-wrapper{
  margin-bottom: 5px;
}
.my-acc-payment-container{
  padding: 10px 10px 60px;
}
.form-control:focus{
  border-color: inherit;
  box-shadow: none;
}

.web-form-brother{
  background: #F9F9F9 !important;
}

.web-form-check{
  text-align: left !important;
  border-radius: 5px;
  padding: 0 0 !important;
  background: none;
}

.web-form-btn{
  margin-top: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-enter-pointer{
  cursor: pointer;
}
.disable-web-form .form-heading-label{
  opacity: 0.5;
}
.web-form-btn{
  padding: 0 25% !important;
}
@media screen and (min-width: 600px) {
  .form-contianer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-brother, .form-brother-check {
    width: 508px;
  }
 
  .row {
    --bs-gutter-x: 0.3rem;
  }
}

.form-brother > * {
  overflow-wrap: break-word !important;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

@media screen and (max-width: 600px) {
  .web-form-btn{
    padding: 0 20%;
  }
}
.web-form-container{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media (min-width:768px){
  .form-brother{
    padding: 20px 42.5px 30px 42.5px !important;
  }
  .form-check-padding {
    padding: 15px 50px !important;
  }
  .form-padding{
    padding: 20px 42.5px 20px 42.5px !important;;
  }
}
@media screen and (min-width: 321px) and (max-width: 767px) {
  .form-brother{
    padding: 20px 30px 30px 30px !important;
  }
  .form-check-padding {
    padding: 15px 30px !important;
  }
  .form-padding{
    padding: 20px 30px 20px 30px !important;;
  }
}
