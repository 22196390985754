.select-country-wrapper{
    background: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-top: 15px;
    border-radius: 5px;
}
.feature-switch-country-label{
    font-size: 16px;
    line-height: 23px;
    color: #2D2D2D;
}
.country-wise-feature-list-wrapper{
    margin-top: 40px;
    background-color: #fff;
}
.feature-list-item-wrapper{
    display: flex;
    justify-content: space-evenly;
    padding:15px;
}
.feature-list-item-wrapper .form-check{
    margin-right: 20px;
}
.feature-list-item-wrapper  .enable-disable-wrapper{
    display: flex;
    align-items: center;
}
.grid-line{
    border-bottom: 1px solid #ccc;
    width: 70%;
    margin: 0 auto;
}
.country-wise-feature-list-wrapper .feature-list-outer-wrapper:last-child .grid-line{
    border: none
}
.feature-list-side-header{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #2D2D2D;
    padding-top: 20px;
}
.enable-disable-wrapper  .form-check .form-check-label{
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    margin-top: -5px;
    font-size: 12px;
}
.enable-disable-wrapper  .form-check .form-check-label.enable{
    color: #0E6245;
    background: #CBF4C9;
}
.enable-disable-wrapper  .form-check .form-check-label.disable{
    color: #A41C4E;
    background: #FDE2DD;
}
.feature-name{
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 0px;
    text-align: left;
    width: 20%;
}
.admin-feature-switch-btn-wrapper{
    text-align: center;
    margin-top: 15px;
}
.grid-action-btn-wrapper button{
    width: 50px;
    height: 25px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}
.admin-overlay-modal-content .highlight-text{
    color: #008393;
}
.admin-overlay-modal-content .feature-switch-enabled-text{
    color: #0E6245;
    background: #CBF4C9;
    border-radius: 5px;
    padding: 4px;
}
.admin-overlay-modal-content .feature-switch-disabled-text{
    color: #A41C4E;
    background: #FDE2DD;
    border-radius: 5px;
    padding: 4px;
}
.feature-switch-country-list{
    width: 25%;
}