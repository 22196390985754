.payment-options-comp{
    margin-top: 25px;
}
.payment-options-comp > h2{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 15px;
}
.payment-option-tab-content-wrapper{
    background: #fafafa;
    min-height: 700px;
    border: 1px solid #1965E1;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.payment-option-tab-btn-container .tab-btn-wrapper .nav-tabs{
    border-bottom: none;
}
.payment-option-tab-btn-container .tab-btn-wrapper .nav li.nav-item{
    width: 290px;
}
.payment-option-tab-btn-container .tab-btn-wrapper .nav .nav-item a{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-color: #DDDDDD #DDDDDD #1965E1;
    background: #fff;
    color: #545454 !important;
    cursor: pointer;
}
.payment-option-tab-btn-container .tab-btn-wrapper .nav .nav-item a.active{
    background-color: #F9F9F9;
}
.payment-option-tab-btn-container .tab-btn-wrapper .nav .nav-item .nav-link.active{
    border-color:#1965E1 #1965E1 transparent;
}
.payment-options-spinner-wrapper{
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}
.payment-options-spinner-wrapper .spinner-border{
    width:50px;
    height: 50px;
    border-width: 3px;  
}
.payment-options-spinner-wrapper .spinner-border:before,
.payment-options-spinner-wrapper .spinner-border:after{
    position: inherit;
}

.payment-method-accordian-wrapper .accordion-item .accordion-header .accordion-button:before{
    content: "";
    background-image: url("../../../assets/images/payment-method-indication.svg");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 10px;
}

.payment-method-accordian-wrapper .accordion-item .accordion-header .accordion-button:not(.collapsed):before{
    background-image: url("../../../assets/images/payment-method-indication-filled.svg");
}

.payment-method-accordian-wrapper .accordion-item .accordion-header .accordion-button:not(.collapsed){
    color: inherit;
    background-color: transparent;
    box-shadow: none;
}

.payment-option-tab-content-wrapper .tab-content .tab-pane #error-alert{
    padding: 10px;
}
.payment-option-tab-content-wrapper .tab-content .tab-pane #error-alert .alert{
    margin-bottom: 0px;
}

.payment-option-tab-content-wrapper .tab-content .tab-pane div #zuora_payment iframe{
    border-radius: 5px !important;
}