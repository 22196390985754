.admin-user-view-wrapper{
    background: #fff;
}
.admin-table-action-container{
    display: flex;
    justify-content: space-between;
}
.admin-table-action-container .button-container{
    display: grid;
}
.admin-table-action-container .button-container button{
    width: 105px;
    height: 40px;
    padding: 9px;
    margin-left: 15px;
}
.admin-table-action-container .button-container .link-container{
    text-align: right;
}
.admin-table-action-container .button-container .search-container{
    margin-top: 15px;
}
.edit-user-image{
    width: 25px;
    height: 25px;
    cursor: pointer;
}