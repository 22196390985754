.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.web-connection-sprinner-wrapper{
  position: relative;
  left:0%;
  -ms-transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  -moz-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
}
.spinner-border {
  width: 90px;
  height: 90px;
  border-width: 8px;
  border-color: #e5e5e5;
  border-right-color: #008393;
  border-top-right-radius: 50%;
}
.spinner-border:before,
.spinner-border:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #008393;
  position: absolute;
  left: 61px;
}
.spinner-border:before {
  top: 3.5px;
}

.spinner-border:after {
  bottom: 3.5px;
}

.spinner-wrapper > div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.apply-overlay-bg{
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  height: 100%;
  position: absolute;
  inset:0
}
.device-not-connected-wrapper .loader-overlay-wrapper{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

