.user-activity-history .table thead tr th, 
.email-log-container .table thead tr th{
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}
.user-activity-history .table td,
.email-log-container .table td{
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}