.mobile-err{
    display: block;
}
input[type="tel"]:focus-visible {
    outline: none;
}
.form-floating input[type="tel"]:focus-visible{
    color: #1965E1;
}
.PhoneInputInput,.PhoneInputInput:focus{
    box-shadow: none;
    border: none;
}
.mobile-err-input{
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('../../../assets/images/error.svg') !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.PhoneInputCountryIcon {
    margin-left: 3px;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url("../../../assets/images/question-mark-warning.svg") no-repeat;
    background-size: 16px 16px;
}
.PhoneInputCountryIcon svg{
    display: none;
}
.web-tel .form-control{
    border: none !important;
  border-bottom: 0.5px solid #545454 !important;
  border-radius: 0;
  padding-left: 0 !important;
  background: none;
}
.web-tel input{
    background: none;
}
.web-tel label{
    font-weight: 400;
    font-size: 12px;
    opacity: .65;
}
.web-tel .mobile-err-input{
    border-color: #dc3545 !important;
}
.invalid-feedback{
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
.form-floating .PhoneInput--focus{
    color: #1965E1 !important;
    border-bottom: 0.5px solid #1965E1 !important;
}

.PhoneInputCountrySelectArrow{
    border-style: solid;
    border-color: rgb(33, 37, 41);
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity:  0.45
}

