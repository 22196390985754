.welcome-header-wrapper{
  color: #000;
  margin-bottom: 10px;
  margin-top: 48px;
}
.bar{
  margin-top: 35px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 6px;
}

.brotherPlusBar {
  background-color: #11339D; /* Brother Plus color */
}
  
.ecoProBar {
  background-color: #007E8F; /* EcoPro color */
}
