.footer{
    background: #ffffff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px;       
}
.footer .privacy-policy{
    margin-right: 10px;
}
.footer a{
    color: #545454 !important;
    cursor: pointer;
}
.footer .terms-use{
    margin-left: 10px;
}
.footer  .privacy-terms{
    margin-bottom: 30px;
}