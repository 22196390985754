.orders-detail-page-wrapper{
    display: flex;
}
.orders-detail-page-wrapper .desktop-app-back-btn{
    flex: 0 0 auto;
    width: 100px; 
}
.order-details-content{
    flex: 1;
}
.orders-detail-page-wrapper .web-subheader .page-title-back-btn{
    position: static;
}