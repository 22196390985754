.subheader {
  text-align: center;
  margin-bottom: 20px;
}

.subheader > * {
  margin: 10px 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}
.subheader p{
  padding-left: 50px;
  padding-right: 50px;
}
.sub-step-info{
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}