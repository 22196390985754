.admin-alert{
    transition: opacity 0.5s linear;
    opacity: 1;
    padding: 9px;
    font-size: 14px;
   line-height: 16px;
}
.admin-alert .btn-close{
    margin-left: 30px !important;
    background: url('../../../assets/images/close.svg') center no-repeat !important;
    opacity: 1 !important;
    margin-top: -9px;
    background-size: 10px 10px !important;
}
.admin-alert.fade:not(.show){
    display: none;
}
.admin-alert.fade.hide{
    opacity: 0;
}