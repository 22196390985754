.bonus-pages-container {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    align-items: baseline;
    justify-content: space-between;
}

.bonus-pages-title {
    color: #000;
    font-family: "Helvetica Neue LT W1G";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32.4px;
}

.bonus-pages-subtitle {
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
}

.bonus-pages-count {
    font-size: 2.5rem;
    color: #363636;
    margin-top: 10px;
}

.bonus-pages-count span {
    font-size: 1.8rem;
    color: #333;
}
.bonus-pages-container  .pages-used-loader-wrapper{
    margin-top: 20px;
    width: 100% !important;
}
