.hardware-choose-printer-container{
    margin-top: 89px;
    max-width: 890px !important;
}

.choose-printer-header{
    text-align: center;
    font-weight: 500;
    color: #212121;
    font-size: 36px;
    line-height: 43px;
}

.hardware-printer-content-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 70px;
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1023px) {
    .hardware-choose-printer-container{
        margin-top: 89px;
        max-width: 700px !important;
    }
    .hardware-printer-content-wrapper{
        display: block;
    }
}

/* MOBILE */
@media (max-width: 767px) {
    .hardware-choose-printer-container{
        margin-top: 50px;
        max-width: none !important;
        padding: 0 12px !important;
    }
    .hardware-printer-content-wrapper{
        display: block;
    }
    .choose-printer-header{
        margin-top: 90px;
        padding: 0 30px;
        font-size: 26px;
        line-height: 31px;
    }
}


